@use "sass:math";
@import "../../style-variables";




.segment {
  // background-color: red;
  font-size: 0.75rem;


  .stats {
    // background-color: blue;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 0.75fr;
    align-items: center;

    grid-gap: calc($grid-padding * 0.5);

    border: 1px solid $white;
    padding: calc($grid-padding * 0.75);

    &:not(.upcoming) {
      cursor: pointer;
    }

    // &>div {
    //   border: 1px solid green;
    // }

    &.upcoming {
      display: grid;
      // grid-template-columns: 5fr 4fr 4fr 8fr;

      border: 1px solid rgba($white, 0.25);
      color: rgba($white, 0.25);

    }

    .title {
      white-space: nowrap;
      h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem;
      }
    }

    .challenges {
      display: flex;
      flex-direction: row;
      gap: calc($grid-padding * 0.5);
      justify-content: center;
      align-content: center;
      align-items: center;
      // background-color: red;
      // justify-content: center;
      // align-items: center;
      // align-content: center;

      .challenge {
        // background-color: blue;

        &.completed {
          // background-color: green;
          svg {
            fill: $white;

            path {
              fill: $white;
            }
          }
        }

        svg {
          display: block;
          width: 1rem;
          height: auto;
          fill: rgba($white, 0.25);

          path {
            fill: rgba($white, 0.25);
          }
        }
      }

    }

    .streaks {
      // background-color: green;
      display: flex;
      flex-direction: row;
      gap: calc($grid-padding / 2.5);
      justify-content: center;
      align-content: center;
      align-items: center;

      span {
        // content: "h";
        // display: block;
        width: 7px;
        height: 7px;
        background-color: rgba($white, 0.25);
        border-radius: 100%;

        &.completed {
          background-color: $brand-primary;
        }
      }
    }

    .addedPoints {
      // background-color: purple;
      // min-width: 48px;
      text-align: right;
      color: $brand-primary;
    }

    .totalPoints {
      // background-color: purple;
      // min-width: 48px;
      text-align: right;
      color: $white;
    }

    .upcomingTimeLeft {
      text-align: right;
      grid-column: span 2;
      white-space: nowrap;
      font-size: 0.625rem;
    }

  }
}