@use "sass:math";
@import "../../style-variables.scss";


.header {
    position: absolute;
    left: $grid-padding;
    right: $grid-padding;
    top: $grid-padding;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $grid-padding * 2;

    .quizNavigation {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: $grid-padding * .5;

        button {
            padding: 0.25rem 1rem;
            margin: 0;

            &:hover,
            &:focus,
            &:active {
                border: 1px solid $text-color;
                background-color: $background-color;
                color: $text-color;
            }
        }

        p.questionPagination {
            margin: 0;
            margin-left: $grid-padding * .5;
        }


    }

    p.timer {
        align-self: center;
        margin: 0;
        padding: 0;
    }
}

.narrow {
    width: 100%;
    max-width: 24.875rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    button {
        margin: 0 auto;
        margin-top: $grid-padding * 2;
    }

    a.button {
        margin: 0 auto;
        font-size: 0.875em;
        line-height: 1.25;
        font-family: "MessinaSans", sans-serif;
        border-radius: $border-radius;
        outline: none;
        border: 1px solid $text-color;
        background-color: $background-color;
        color: $text-color;
        padding: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        transition: all $animation-speed ease;
        cursor: pointer;
        margin-top: 2rem;

        &:hover,
        &:active,
        &:focus {
            border: 1px solid $text-color;
            background-color: $text-color;
            color: $background-color;
        }
    }

    .error {
        color: $brand-danger;
    }
}




.form {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-top: $grid-padding * 3;

    p:has(a) {
        margin-bottom: $grid-padding * .5;
    }

    button {
        margin: 0 auto;
        margin-top: 2rem;

        &:hover,
        &:focus,
        &:active {
            border: 1px solid $text-color;
            background-color: $background-color;
            color: $text-color;
        }
    }

    button.submit {
        color: $brand-quinary;
        border-color: $brand-quinary;
    }
}