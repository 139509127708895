@use "sass:math";
@import "../../style-variables";


.userEntry {
  display: block;
  // cursor: pointer;
  color: $white;
  padding: math.div($grid-padding, 1.5) $grid-padding;
  @media (min-width: $breakpoint-extra-large) {
    padding: math.div($grid-padding, 1.5) 0;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid lighten($background-color, 15%);
  }
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
  h3 {
    color: inherit;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
      margin-left: math.div($grid-padding, 2);
    }
  }
  .user {
    display: flex;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    // margin-right: 1rem;
    .circle {
      position: relative;
      background-color: lighten($black, 70%);
      // background-color: lighten($background-color, 15%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      flex: 0 0 2.5rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .svgAnimation {
        position: absolute;
        width: 100%;
        height: 32px;
        left: 0;
        top: 2px;
        display: block;
      }
      .placeholder {
        width: 1.5rem;
        height: 1.5rem;
        fill: lighten($black, 60%);
      }
      // img {
      //   display: block;
      //   width: 100%;
      //   max-width: 100%;
      //   height: auto;
      // }
      // .profileImage {
      //   width: 25rem;
      //   height: 2.5rem;
      //   display: block;
      //   img {
      //     width: 100%;
      //     max-width: 100%;
      //     height: auto;
      //     display: block;
      //   }
      // }
    }
    h3 {
      line-height: 1.2;
      font-size: 0.875rem;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      @media (min-width: $breakpoint-small) {
        font-size: 1.25rem;
      }
    }
  }
}

.outerContainer {
  cursor: pointer;
  // background-color: red;
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

    border-bottom: 1px solid lighten($background-color, 15%);
  
}
.innerContainer {
  // background-color: blue;
  display: flex;
  flex-direction: column;
  // width: 2.5rem;
  flex: 1 1;
  max-width: 100%;
  overflow: hidden;
}

.selector {
  // position: relative;
  // z-index: 99;
  // background-color: orange;
  flex: 0 0 2.5rem;
  width: 2.5rem;
  margin-right: $grid-padding;
  display: flex;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  // margin-left: 1rem;
  &.active {
    & > .circle {
      background-color: $brand-tertiary;
    }
  }
  .circle {
    cursor: pointer;
    // background-color: lighten($black, 70%);
    border: 1px solid $white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    // margin-left: 0.5rem;
    flex: 0 0 2.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .placeholder {
      width: 1.5rem;
      height: 1.5rem;
      fill: lighten($black, 60%);
    }
  }
}