@import "../../style-variables";

.row {
  display: flex;
  overflow: hidden;
  width: 100%;
  // max-width: 100%;
  // border: 1px solid red;
  &.wrap {
    flex-wrap: wrap;
  }
  &.grid {
    width: unset;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(48px, 1fr));
    grid-gap: 1rem;
    // border: 1px solid red;
    // justify-content: space-evenly;
    // margin: -0.5rem;
  }
  &:not(:last-of-type) {
    margin-bottom: $grid-padding * 1.5;
  }
  &.smallMarginBottom {
    margin-bottom: 0.25rem;
  }
}
