@use "sass:math";
@import "../../style-variables";


.container {
  width: 100%;
  max-width: 20rem;
  // padding: 1rem;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;

    picture {
      width: 100%;
      height: 0;
      padding-top: 100%;
      position: relative;
      display: block;
      z-index: -1;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    input {
      &[type="file"] {
        display: none;
      }
      &[type="submit"] {
        margin-top: 1rem;
      }
    }

    label {
      display: block;

      &>input {
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-top: 0.25rem;
      }

      &>.formRow {
        margin-top: 0.25rem;
      }
    }

    .formRow {
      display: flex;
      justify-content: space-between;
      gap: 1rem;
      width: 100%;
      max-width: 100%;

      input {
        width: 100%;
        max-width: 100%;
      }
    }

    .button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      text-align: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      &:hover {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }
    }

    .warning {
      margin: 0;
    }

    .error {
      color: $brand-danger;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-danger;
    }

    .success {
      color: $brand-success;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-success;
    }

    .createButton {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      border-color: $brand-success;
      color: $brand-success;

      &:hover,
      &:active,
      &:focus {
        color: $black;
        background-color: $text-color;
        border-color: $text-color;

      }
    }

  }
}