@use "sass:math";
@import "../../style-variables.scss";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  position: absolute;
  padding: $grid-padding;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.video {
  display: block;
  position: relative;
  // margin-top: -8rem;
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  z-index: -1;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: scroll;
  margin-left: - $grid-padding;
  margin-right: - $grid-padding;

  .buttonGroup {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: ($grid-padding * 3) ($grid-padding * 2);
    max-width: 20rem;
    margin: auto;

    :not(:last-child) {
      margin-bottom: math.div($grid-padding, 2);
    }

    button {
      display: flex;
      align-items: center;

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }

    a.button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }
  }
}

.small {
  font-size: 0.625em;
  color: lighten($background-color, 50%);
}


.noWorkoutContainer {
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $grid-padding;
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: $boxed-width;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .circle {
    cursor: pointer;
    border: 1px solid $white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-danger;
    margin-bottom: 1rem;

    .placeholder {
      width: 1.5rem;
      height: 1.5rem;
      fill: $white;
    }
  }

  h2 {
    text-align: center;
  }

  p,
  a {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    font-size: 0.875rem;
    font-family: "MessinaSans", sans-serif;

    .email {
      text-transform: none;
      color: $brand-danger;
      letter-spacing: .05em;
    }
  }

  .buttonGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: ($grid-padding * 2) ($grid-padding * 2);

    .deleteButton {
      background-color: $brand-danger;
      color: $text-color;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($brand-danger, 15%);
        color: $text-color;
      }
    }
  }
}