@use "sass:math";
@import "../../style-variables.scss";

.workoutSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  .selectType {
    width: 100%;
    display: flex;
    align-items: center;
    .selectLeft {
      flex: 0 0 32px;
      display: inline-block;
      line-height: 1;
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(-70%, -50%);
        left: 50%;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 12px solid white;
      }
    }
    .selectRight {
      flex: 0 0 32px;
      display: inline-block;
      line-height: 1;
      position: relative;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      cursor: pointer;
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translate(-30%, -50%);
        left: 50%;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 12px solid white;
      }
    }
    .workoutType {
      width: 100%;
      font-size: 2.25rem;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
  }
  .workoutTime {
    position: relative;
    border: 0;
    padding: 0;
    display: block;
    margin: 0;
    margin-top: 0.5rem;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-size: 16px 12px;
  }
}


  .highscore {
    position: relative;
    color: $text-color !important;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    &.qualified {
      &:nth-child(n + 17) {
        color: rgba($text-color, 0.65) !important;
        h3.position {
          color: rgba($text-color, 1) !important;

        }
      }
    }
    .user {
      // position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      text-overflow: ellipsis;
      margin-right: 1rem;
      .circle {
        position: relative;
        background-color: lighten($black, 70%);
        // background-color: lighten($background-color, 15%);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 0.5rem;
        flex: 0 0 2.5rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .svgAnimation {
          position: absolute;
          width: 100%;
          height: 32px;
          left: 0;
          top: 2px;
          display: block;
        }
        .placeholder {
          width: 1.5rem;
          height: 1.5rem;
          fill: lighten($black, 60%);
        }
        // img {
        //   display: block;
        //   width: 100%;
        //   max-width: 100%;
        //   height: auto;
        // }
        // .profileImage {
        //   width: 25rem;
        //   height: 2.5rem;
        //   display: block;
        //   img {
        //     width: 100%;
        //     max-width: 100%;
        //     height: auto;
        //     display: block;
        //   }
        // }
      }
      .userNameContainer {
        // display: flex;
        // flex-direction: column;
        overflow: hidden;
        align-items: flex-start;
        justify-content: center;
        text-overflow: ellipsis;
        // margin-right: 1rem;
        h3 {
          line-height: 1.2;
          font-size: 0.875rem;
          margin-bottom: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          @media (min-width: $breakpoint-small) {
            font-size: 1.25rem;
          }
        }
        p {
          color: inherit;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.625em;
          margin-bottom: 0;
          // @media (min-width: $breakpoint-small) {
          //   font-size: 1.25rem;
          // }
        }
      }
      h3.position {
        line-height: 1.2;
        font-size: 0.875rem;
        margin-bottom: 0;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        // overflow: hidden;
        
        position: absolute;
        left: 0;
        bottom: -0.25rem;
        // font-size: 1.25rem;
        text-shadow: 1px 1px 4px rgba($background-color, 0.85);
        @media (min-width: $breakpoint-small) {
          font-size: 1.25rem;
        }
      }
    }
    .statsColumn {
      margin-left: auto;
    }
  }


