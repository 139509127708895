@use "sass:math";
@import "../../style-variables";

.challenge {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  // cursor: pointer;
  color: $white;
  padding: math.div($grid-padding, 1) $grid-padding;
  // &:not(:last-of-type) {
  &:first-of-type {
    border-top: 1px solid lighten($background-color, 15%);
  }

  // border-bottom: 1px solid lighten($background-color, 15%);
  // }
  @media (min-width: $breakpoint-extra-large) {
    padding: math.div($grid-padding, 1) 0;
  }
  &:not(:last-of-type) {
  border-bottom: 1px solid lighten($background-color, 15%);
  }
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }

  .info {
    display: flex;
    margin-bottom: 0.25rem;
    justify-content: center;

    .action {
      font-size: 0.75em;
      font-weight: 700;
      // margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      span:not(.lost):not(.won):not(.draw) {
        text-transform: uppercase;
      }
      .lost {
        color: $brand-quinary;
      }
      .won {
        color: $brand-senary;
      }
      .draw {
        color: $brand-quaternary;
      }
    }
  }

  .challengers {
    display: flex;
    // background-color: pink;
    // justify-content: space-between;
    // max-width: 100%;
    // overflow: hidden;
    // text-overflow: ellipsis;
    align-items: flex-start;
    &.opponent {
      .user:first-of-type {
        order: 1;
      }
      .user:last-of-type {
        order: -1;
      }
    }
  }

  .user {
    // background-color: blue;
    flex: 0 0 2.5rem;

    display: flex;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;
    // margin-left: 1rem;
    .circle {
      position: relative;
      background-color: lighten($black, 70%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      // margin-left: 0.5rem;
      flex: 0 0 2.5rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .placeholder {
        width: 1.5rem;
        height: 1.5rem;
        fill: lighten($black, 60%);
      }
    }
  }

  .details {
    flex: 1 1 0%;
    overflow: visible;
    text-align: center;
    // background-color: red;
    padding: 0 0.5rem;

    h3 {
      color: inherit;
      margin-bottom: 0.25rem;
      text-transform: uppercase;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      svg {
        margin-left: math.div($grid-padding, 2);
      }
    }
    .duration {
      font-size: 0.75em;
      color: lighten($background-color, 50%);
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    .scoreContainer {
      //   background-color: rgba(red, 0.5);
      overflow: visible;
      position: relative;
      display: flex;
      flex-direction: row;
      margin-top: 0.25rem;
      // margin-bottom: 0.25rem;
      justify-content: center;
      line-height: 1;
      .leftScores,
      .rightScores {
        flex: 1 0 0%;
        display: flex;

        div {
          // flex: 1 1 0%;
          // align-self: center;
          //   padding: 0 0.5rem;
          overflow: visible;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $background-color;
            border: 1px solid lighten($background-color, 15%);
            border-radius: 50%;
            width: 0.625rem;
            height: 0.625rem;
            // transform: translateY(-15%);
            &.played {
              &::after {
                content: "";
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                border: 1px solid $background-color;
                background: lighten($background-color, 15%);
                border-radius: 50%;
              }
            }

            &.won {
              &::after {
                content: "";
                display: block;
                width: 0.5rem;
                height: 0.5rem;
                border: 1px solid $background-color;
                background: $brand-quaternary;
                border-radius: 50%;
              }
            }

            &.lost {
              position: relative;
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                // left: 50%;
                display: block;
                width: 1rem;
                height: 1px;
                background-color: $white;
                transform: rotate(-45deg);
              }
              &::after {
                content: "";
                position: absolute;
                top: 50%;
                // left: 50%;
                display: block;
                width: 1rem;
                height: 1px;
                background-color: $white;
                transform: rotate(45deg);
              }
            }
          }
        }
      }
      .leftScores {
        // background-color: green;
        justify-content: flex-start;
        align-items: center;
        flex: 1 1 auto;
        // align-content: flex-start;
        // justify-items: flex-start;
        div {
          //   background-color: red;
          display: flex;
          justify-content: flex-start;
          align-content: flex-start;
          flex: 1 1 auto;
        }
      }
      .rightScores {
        //   background-color: green;
        justify-content: flex-end;
        align-items: center;
        flex: 1 1 auto;
        // align-content: flex-end;
        // justify-items: flex-end;
        div {
          // background-color: red;
          display: flex;
          justify-content: flex-end;
          align-content: flex-end;
          flex: 1 1 auto;
        }
      }
      .score {
        flex: 0 0 auto;
        font-weight: 700;
        font-size: 1.875rem;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        background-color: $black;
        align-self: center;
        padding: 0 0.5rem;
        min-width: 5rem;
      // background-color: blue;
        .draw {
          // color: red;
          font-size: 20px;
          font-family: "MessinaSans", sans-serif;
          font-weight: 900;
          font-style: italic;
          line-height: 1.25;
          // margin: 0 0 1rem 0;
          letter-spacing: -0.05em;
          text-transform: uppercase;
          display: inline-block;
          transform: translateY(-0.25rem);
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          // transform: translateY(-10%);

          left: 0;
          right: 0;
          border-top: 1px solid lighten($background-color, 15%);
          z-index: -1;
        }
      }
    }
  }
}
