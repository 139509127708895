@use "sass:math";
@import "../../style-variables.scss";

.alert {
  position: relative;
  padding: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid transparent;
  border-radius: $border-radius;
  color: $text-color;
  background-color: $background-color;
  border-color: $text-color;
  

  &.dismissible {
    padding-right: 2rem;
    button {
      border-radius: 0;
      margin: 0;
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 1;
    //   opacity: 0.5;
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.25rem 0.5rem;
      color: inherit;
      cursor: pointer;
      letter-spacing: 0;
    //   border: 1px solid blue;
      &:hover,
      &:active {
        opacity: 1;
      }
      span {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: inherit;
        cursor: pointer;
        // border: 1px solid red;
      }
    }
  }
}

.danger {
//   color: darken($brand-danger, 20%);
color: $brand-danger;
//   background-color: lighten($brand-danger, 20%);
  border-color: $brand-danger;
}
.success {
  color: darken($brand-success, 20%);
//   background-color: lighten($brand-success, 20%);
  border-color: $brand-success;
}
