@use "sass:math";
@import "../../style-variables";

.workout {
  display: block;
  cursor: pointer;
  color: $white;
  padding: math.div($grid-padding, 1.5) $grid-padding;
  @media (min-width: $breakpoint-extra-large) {
    padding: math.div($grid-padding, 1.5) 0;
  }
  &.crud {
    cursor: default;
  }
  &:not(:last-of-type) {
    border-bottom: 1px solid lighten($background-color, 15%);
  }
  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }
  h3 {
    color: inherit;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    svg {
      margin-left: math.div($grid-padding, 2);
    }
  }
}

.outerContainer {
  // background-color: red;
  display: flex;
  width: 100%;
  cursor: pointer;
  &:not(:last-of-type) {
    border-bottom: 1px solid lighten($background-color, 15%);
  }
}
.innerContainer {
  // background-color: blue;
  display: flex;
  flex-direction: column;
  // width: 2.5rem;
  flex: 1 0;
  cursor: pointer;

  button,
  .button {
    padding: 0.5rem 1rem;

    &.delete {
      border: 1px solid $text-color;
      background-color: $brand-danger;
      color: $text-color;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: darken($brand-danger, 15%);
        color: $text-color;
      }
    }

    &.verify {
      border: 1px solid $brand-primary;

      color: $brand-primary;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;

        color: $black;
      }
    }


  }
}

.selector {
  // position: relative;
  // z-index: 99;
  // background-color: orange;
  flex: 0 0 2.5rem;
  width: 2.5rem;
  margin-right: $grid-padding;
  display: flex;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  cursor: pointer;
  // margin-left: 1rem;
  &.active {
    & > .circle {
      background-color: $brand-tertiary;
    }
  }
  .circle {
    cursor: pointer;
    // background-color: lighten($black, 70%);
    border: 1px solid $white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    // margin-left: 0.5rem;
    flex: 0 0 2.5rem;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .placeholder {
      width: 1.5rem;
      height: 1.5rem;
      fill: lighten($black, 60%);
    }
  }
}

