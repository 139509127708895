@use "sass:math";
@import "../../style-variables.scss";


.marginBottom>div:last-child>div:last-child {
    margin-bottom: 3 * $grid-padding;
}


.quiz {
    display: block;
    cursor: pointer;
    color: $white;
    padding: math.div($grid-padding, 1.5) $grid-padding;

    @media (min-width: $breakpoint-extra-large) {
        padding: math.div($grid-padding, 1.5) 0;
    }

    &.crud {
        cursor: default;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid lighten($background-color, 15%);
    }

    &:visited,
    &:hover,
    &:active,
    &:focus {
        color: inherit;
    }

    h3 {
        color: inherit;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
            margin-left: math.div($grid-padding, 2);
        }
    }
}

.container {
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;

    @media (min-width: $breakpoint-extra-large) {
        margin-left: 0;
        margin-right: 0;
    }
}

.outerContainer {
    // background-color: red;
    display: flex;
    width: 100%;
    cursor: pointer;

    &:not(:last-of-type) {
        border-bottom: 1px solid lighten($background-color, 15%);
    }
}

.innerContainer {
    // background-color: blue;
    display: flex;
    flex-direction: column;
    // width: 2.5rem;
    flex: 1 0;
    cursor: pointer;

    button,
    .button {
        padding: 0.5rem 1rem;

        &.delete {
            border: 1px solid $text-color;
            background-color: $brand-danger;
            color: $text-color;

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $text-color;
                background-color: darken($brand-danger, 15%);
                color: $text-color;
            }
        }

        &.verify {
            border: 1px solid $brand-primary;

            color: $brand-primary;

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $text-color;

                color: $black;
            }
        }


    }
}



.quizStat {
    overflow: hidden;
    flex: 1 0 0%;
    h4,
    p {
      color: inherit;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.625em;
      margin-bottom: 0;
    }
    h4 {
      text-transform: uppercase;
    }
    &:not(:last-of-type) {
      padding-right: 0.5rem;
    }
  }
  .horizontal {
    display: flex;
    justify-content: space-between;
    h4,
    p {
      text-transform: none;
      min-width: 6em;
      
    }
    p {
      text-align: right;
    }
    h4 {
      font-weight: 400 !important;
      font-style: normal;
      min-width: 3.5rem;
      margin-bottom: 0.25rem;
    }
    p {
      font-weight: 900 !important;
    }
    &:not(:last-of-type) {
      padding-right: 0;
    }
  }
  