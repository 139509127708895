@use "sass:math";

$brand-primary: #00d1ff;
$brand-secondary: #00ff00;
$brand-danger: #eb3547;
$brand-success: $brand-secondary;
$brand-tertiary: #33972a;
$brand-quaternary: #ffe600;
$brand-quinary: #e13b3b;
$brand-senary: #39D0FF;
$black: #101010;
$white: #ffffff;

$background-color: $black;
$text-color: $white;

$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-extra-large: 1200px;

$border-radius: 0;
$grid-padding: 1rem;
$boxed-width: 75rem;

$animation-speed: 0.1s;
