@use "sass:math";
@import "../../style-variables";

.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.searchForm {
  margin-bottom: 2rem;

  .formRow {
    display: flex;

    input {
      flex: 1 1 100%;
    }

    .searchButton {
      border-left: 0;
      line-height: 1;
      margin: 0;

      &:hover,
      &:active,
      &:focus {
        svg {
          path {
            fill: $black;
          }
        }
      }
    }

    input[type="checkbox"] {
      position: absolute;
      display: none;

      &:hover,
      &:active,
      &:focus {
        &+.verifiedCheckbox {
          background-color: $text-color;
          border-color: $text-color;
          color: $black;
        }
      }

      &:checked {
        &+.verifiedCheckbox {
          background-color: $text-color;
          border-color: $text-color;
          color: $black;
        }
      }
    }

    .verifiedCheckbox {
      cursor: pointer;
      letter-spacing: 0.2em;
      text-transform: uppercase;
      margin-top: 1rem;
      padding: 0.5rem 1rem;
    }
  }
}

.createButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;

  .createButton {
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    border-color: $brand-success;
    color: $brand-success;

    &:hover,
    &:active,
    &:focus {
      color: $black;
      background-color: $text-color;
      border-color: $text-color;

    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .circle {
    cursor: pointer;
    border: 1px solid $white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-danger;
    margin-bottom: 1rem;

    .placeholder {
      width: 1.5rem;
      height: 1.5rem;
      fill: $white;
    }
  }

  h2 {
    text-align: center;
  }
  p,
  a {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    font-size: 0.875rem;
    font-family: "MessinaSans", sans-serif;

    .email {
      text-transform: none;
      color: $brand-danger;
      letter-spacing: .05em;
    }
  }

  .buttonGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: ($grid-padding * 2) ($grid-padding * 2);

    .deleteButton {
      background-color: $brand-danger;
      color: $text-color;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($brand-danger, 15%);
        color: $text-color;
      }
    }
  }
}