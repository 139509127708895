@use "sass:math";
@import "../../style-variables";



.rank {
  // background-color: blue;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.625rem;

  .liveRank {
    // position: relative;
    color: $brand-danger;
    text-transform: uppercase;
    font-size: 0.5rem;
    letter-spacing: 0.1rem;
    // text-align: right;
    // white-space: nowrap;
    // margin-right: 8px;
    top: 50%;
  transform: translateY(-50%);
    position: absolute;
    right: 100%;
    width: 200px;
    margin-right: 8px;
    
    &:before {
      content: "Live";
      @media (min-width: 360px) {
        content: "Live Rank";
      }
  
    }




    &:after {
      content: "";
      display: inline-block;
      width: calc($grid-padding / 2.75);
      height: calc($grid-padding / 2.75);
      background-color: $brand-danger;
      border-radius: 50%;
      margin-left: calc($grid-padding / 4);
      animation: blink 2s infinite;

      @keyframes blink {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }


    }
  }
}