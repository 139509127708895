@use "sass:math";
@import "../../style-variables";

.subtitle {
  letter-spacing: 0;
  text-align: center;
  margin: 1rem 1rem 2rem 1rem;
}
.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}