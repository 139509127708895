@use "sass:math";
@import "../../style-variables";


.userEntry {
  display: block;
  color: $white;
  padding: math.div($grid-padding, 1.5) $grid-padding;

  @media (min-width: $breakpoint-extra-large) {
    padding: math.div($grid-padding, 1.5) 0;
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid lighten($background-color, 15%);
  }

  &:visited,
  &:hover,
  &:active,
  &:focus {
    color: inherit;
  }

  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3 {
    color: inherit;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      margin-left: math.div($grid-padding, 2);
    }
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    font-size: .625em;

    &.notVerified {
      color: $brand-primary;
    }

    &.signedUp {
      color: lighten($background-color, 50%);
      
    }
  }

  .user {
    display: flex;
    overflow: hidden;
    align-items: center;
    text-overflow: ellipsis;

    .circle {
      position: relative;
      background-color: lighten($black, 70%);
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      margin-right: 0.5rem;
      flex: 0 0 2.5rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .svgAnimation {
        position: absolute;
        width: 100%;
        height: 32px;
        left: 0;
        top: 2px;
        display: block;
      }

      .placeholder {
        width: 1.5rem;
        height: 1.5rem;
        fill: lighten($black, 60%);
      }
    }

    h3 {
      line-height: 1.2;
      font-size: 0.875rem;
      margin-bottom: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @media (min-width: $breakpoint-small) {
        font-size: 1.25rem;
      }
    }
  }
}

.outerContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid lighten($background-color, 15%);

}

.innerContainer {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
  max-width: 100%;
  overflow: hidden;

  @media (min-width: $breakpoint-small) {
    flex: 1 1 auto;
    max-width: auto;
  }

}

.actionContainer {
  justify-content: flex-end;
  flex: 1 0 100%;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  gap: 1rem;
  padding: math.div($grid-padding, 1.5) $grid-padding;

  @media (min-width: $breakpoint-small) {
    flex: 0 0 auto;
    max-width: auto;
  }

  @media (min-width: $breakpoint-extra-large) {
    padding: math.div($grid-padding, 1.5) 0;
  }

  .button {
    border-color: $text-color;
    color: $text-color;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $text-color;
      background-color: $text-color;
      color: $black;
    }
  }
  
  .select {
    min-width: 100px;
    position: relative;
    border-color: $text-color;
    color: $text-color;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    padding: 0.5rem 2.25rem 0.5rem 0.75rem;
    background-size: 16px 12px;
    font-size: 0.5rem;
  
    &:hover,
    &:active,
    &:focus {
      background-color: $text-color;
      color: $background-color;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23101010' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
      }
  }

  button,
  .button {
    padding: 0.5rem 1rem;

    &.delete {
      border: 1px solid $text-color;
      background-color: $brand-danger;
      color: $text-color;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: darken($brand-danger, 15%);
        color: $text-color;
      }
    }

    &.verify {
      border: 1px solid $brand-primary;

      color: $brand-primary;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;

        color: $black;
      }
    }


  }
}