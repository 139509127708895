@use "sass:math";
@import "../../style-variables.scss";

.newsContainer {
  position: relative;
  height: auto;
  display: block;
  // z-index: -1;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: white;
//   border: 1px solid orange;
overflow: hidden;

  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }
  
  .arrowTop {
    position: absolute;
    fill: $background-color;
    // opacity: 0.2;
    top: -2px;
    left: -10px;
    width: 110%;
  }

  .news {
    // padding-top: 20%;

    color: $background-color;
    // border: 1px solid blue;
    padding-bottom: 1rem;
    .newsInner {
      padding-top: 16%;
      padding-left: 1rem;
      padding-right: 1rem;
      // @media (min-width: $breakpoint-small) {
      //   padding-left: 2rem;
      //   padding-right: 2rem;
      // }
      // @media (min-width: $breakpoint-medium) {
      //   padding-left: 2rem;
      //   padding-right: 2rem;
      // }
      // @media (min-width: $breakpoint-large) {
      //   padding-left: 8rem;
      //   padding-right: 8rem;
      // }

      h2 {
        top: 0;
        position: absolute;
        left: 1rem;
        right: 1rem;
        color: $white;

        @media (min-width: $breakpoint-small) {
          margin-top: 5%;
        }
        @media (min-width: $breakpoint-medium) {
          margin-top: 8%;
        }
        @media (min-width: $breakpoint-extra-large) {
          left: 0;
          right: 0;
        }
        // top: 5%;
        // border: 1px solid red;
        
      }
    }
  }
}

.messageList {
  //   border: 1px solid blue;
  //   display: flex;
  //   flex-direction: row;
  //   flex-wrap: nowrap;
  //   justify-content: stretch;
  //   justify-content: stretch;
  //   align-items: stretch;
  //   align-content: stretch;


    .message {
      flex: 1 1 100%;
    //   border: 1px solid red;
      color: $background-color !important;
      display: flex;
      align-items: center;
      overflow: hidden;
      align-items: center;
      text-overflow: ellipsis;
        margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      cursor: grab;
      &.link {
        cursor: pointer;
      }
      &.swiping {
        cursor: grab;
      }
      .circle {
        position: relative;
        background-color: lighten($black, 70%);
        // background-color: lighten($background-color, 15%);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        margin-right: 1rem;
        flex: 0 0 2.5rem;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 2px solid red;
        .svgAnimation {
          position: absolute;
          width: 100%;
          height: 32px;
          left: 0;
          top: 2px;
          display: block;
        }
        .placeholder {
          width: 1.5rem;
          height: 1.5rem;
          fill: lighten($black, 60%);
        }
        // img {
        //   display: block;
        //   width: 100%;
        //   max-width: 100%;
        //   height: auto;
        // }
        // .profileImage {
        //   width: 25rem;
        //   height: 2.5rem;
        //   display: block;
        //   img {
        //     width: 100%;
        //     max-width: 100%;
        //     height: auto;
        //     display: block;
        //   }
        // }
      }
      .messageContent {
        h3 {
          line-height: 1.2;
          // font-size: 0.875rem;
          margin-bottom: 0;
          // text-overflow: ellipsis;
          // white-space: nowrap;
          // overflow: hidden;
          // @media (min-width: $breakpoint-small) {
          //   font-size: 1.25rem;
          // }
        }
        p {
          margin-top: 0.125rem;
          margin-bottom: 0;
          font-size: 0.75em;
          span {
              color: $background-color;
          }
        }
        .dateSeparator {
          margin-top: 0.125rem;
          margin-bottom: 0;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // background-color: lighten($background-color, 15%);
          // text-align: center;
          font-size: 0.5em;
          // padding: 0.25rem;
          color: lighten($background-color, 50%);
        }
      }
    }

}

.deleteAction {
  background-color: $brand-danger;
  color: $white;
  display: flex;
  justify-content: END;
  align-items: center;
  padding: 1rem;
  margin-left: 1rem;
}

// .leading-actions {
//   display: flex;
// }

// .leading-actions > * {
//   width: 0;
//   overflow-x: hidden;
// }

// .swipeable-list {
//   flex: 1;
//   width: 100%;
//   height: 100%;
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
// }

// .swipeable-list-item {
//   position: relative;
//   transition: max-height 0.5s ease-in-out;
//   max-height: 1000px;
//   transform-origin: top;
//   overflow: hidden;
//   width: 100%;
// }

// .swipeable-list-item--remove {
//   max-height: 0;
//   transition: max-height 0.35s ease-out;
// }

// .swipeable-list-item__content {
//   width: 100%;
//   align-items: center;
//   box-sizing: border-box;
//   height: 100%;
//   display: flex;
// }

// .swipeable-list-item__content--return {
//   transition: transform 0.5s ease-in-out;
// }

// @-webkit-keyframes content-return-leading-ms {
//   20% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

// @keyframes content-return-leading-ms {
//   20% {
//     transform: translateX(100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

// .swipeable-list-item__content--return-leading-ms {
//   -webkit-animation-duration: 0.5s;
//   animation-duration: 0.5s;
//   -webkit-animation-name: content-return-leading-ms;
//   animation-name: content-return-leading-ms;
//   -webkit-animation-timing-function: linear;
//   animation-timing-function: linear;
// }

// @-webkit-keyframes content-return-trailing-ms {
//   20% {
//     transform: translateX(-100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

// @keyframes content-return-trailing-ms {
//   20% {
//     transform: translateX(-100%);
//   }
//   100% {
//     transform: translateX(0);
//   }
// }

// .swipeable-list-item__content--return-trailing-ms {
//   -webkit-animation-duration: 0.5s;
//   animation-duration: 0.5s;
//   -webkit-animation-name: content-return-trailing-ms;
//   animation-name: content-return-trailing-ms;
//   -webkit-animation-timing-function: linear;
//   animation-timing-function: linear;
// }

// .swipeable-list-item__content--remove {
//   transition: transform 0.5s ease-in-out;
// }

// .swipeable-list-item__leading-actions {
//   position: absolute;
//   display: flex;
//   width: 0px;
//   overflow: hidden;
//   height: 100%;
// }

// .swipeable-list-item__leading-actions--return {
//   transition: width 0.5s ease-in-out;
// }

// @-webkit-keyframes actions-return-ms {
//   20% {
//     width: 100%;
//   }
//   100% {
//     width: 0;
//   }
// }

// @keyframes actions-return-ms {
//   20% {
//     width: 100%;
//   }
//   100% {
//     width: 0;
//   }
// }

// .swipeable-list-item__actions--return-ms {
//   -webkit-animation-duration: 0.5s;
//   animation-duration: 0.5s;
//   -webkit-animation-name: actions-return-ms;
//   animation-name: actions-return-ms;
//   -webkit-animation-timing-function: linear;
//   animation-timing-function: linear;
// }

// .swipeable-list-item__leading-actions > * {
//   overflow: hidden;
//   display: flex;
//   justify-content: flex-end;
//   transform: scale(1);
//   transform-origin: center left;
//   transition: transform 0.2s ease-out;
// }

// .swipeable-list-item__trailing-actions {
//   display: flex;
//   width: 0px;
//   position: absolute;
//   right: 0;
//   top: 0;
//   justify-content: flex-end;
//   overflow: hidden;
//   height: 100%;
// }

// .swipeable-list-item__trailing-actions--return {
//   transition: width 0.5s ease-in-out;
// }

// .swipeable-list-item__trailing-actions > * {
//   overflow: hidden;
//   transform: scale(1);
//   transform-origin: center right;
//   transition: transform 0.2s ease-out;
// }

// .swipeable-list-item__leading-actions--scaled > * {
//   transform: scale(1.2);
//   transform-origin: center left;
//   transition: transform 0.2s ease-in;
// }

// .swipeable-list-item__trailing-actions--scaled > * {
//   transform: scale(1.2);
//   transform-origin: center right;
//   transition: transform 0.2s ease-in;
// }

// .swipe-action {
//   display: flex;
//   width: 100%;
//   align-items: stretch;
// }

// .swipe-action > * {
//   flex: 1;
// }

// .swipe-action__grayed > * {
//   background-color: gray !important;
// }

// .swipe-action__leading > * {
//   justify-content: flex-start;
// }

// .swipe-action__trailing > * {
//   justify-content: flex-end;
// }

// .swipe-action__leading--full-swipe-rest,
// .swipe-action__trailing--full-swipe-rest {
//   width: 0;
//   transition: width 0.2s;
// }

// .swipe-action__leading--full-swipe-main > * {
//   justify-content: flex-end;
// }

// .swipe-action__trailing--full-swipe-main > * {
//   justify-content: flex-start;
// }

// .trailing-actions {
//   display: flex;
// }

// .trailing-actions > * {
//   width: 0;
//   overflow-x: hidden;
// }
