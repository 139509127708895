@use "sass:math";
@import "./style-variables";

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: 400;
  src: url("./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.eot");
  src: url("./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff2")
      format("woff2"),
    url("./Fonts/MessinaSans_Web_Regular/MessinaSansWeb-Regular.woff")
      format("woff");
}

@font-face {
  font-family: "MessinaSans";
  font-style: normal;
  font-weight: 700;
  src: url("./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.eot");
  src: url("./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.woff2")
      format("woff2"),
    url("./Fonts/MessinaSans_Web_Bold/MessinaSansWeb-Bold.woff") format("woff");
}

@font-face {
  font-family: "MessinaSans";
  font-style: italic;
  font-weight: 700;
  src: url("./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.eot");
  src: url("./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.woff2")
      format("woff2"),
    url("./Fonts/MessinaSans_Web_BoldItalic/MessinaSansWeb-BoldItalic.woff")
      format("woff");
}

@font-face {
  font-family: "MessinaSans";
  font-style: italic;
  font-weight: 800;
  src: url("./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.eot");
  src: url("./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.woff2")
      format("woff2"),
    url("./Fonts/MessinaSans_Web_BlackItalic/MessinaSansWeb-BlackItalic.woff")
      format("woff");
}

*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  // border: 0.1px solid red;
}

html,
body,
#root {
  height: 100%;
}

body {
  font-size: 16px;
  margin: 0;
  font-family: "MessinaSans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
  color: $text-color;
  &.menu-open {
    overflow: hidden;
  }
}

p {
  font-size: 0.875rem;
  margin: 0 0 1rem 0;
  letter-spacing: 0.05em;
  a {
    color: $brand-primary;
    &:hover,
    &:active,
    &:focus {
      color: darken($brand-primary, 8%);
    }
  }
  strong {
    font-weight: 700;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "MessinaSans", sans-serif;
  font-weight: 900;
  font-style: italic;
  line-height: 1.25;
  margin: 0 0 1rem 0;
  letter-spacing: -0.05em;
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  text-transform: uppercase;
  font-style: italic;
  line-height: 95%;
  font-weight: 800;
}

h3 {
  font-size: 1.25rem;
}

h1,
.h1 {
  font-size: 2.75rem;
  @media (min-width: $breakpoint-large) {
    font-size: 3.5rem;
  }
}

.h1_smaller {
  font-size: 2em;
}

h2, .h2 {
  font-size: 1.5em;
}

:focus {
  outline: 0;
}

input,
select,
textarea,
button,
.button {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.25;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $text-color;
  background-color: $background-color;
  color: $text-color;
  padding: 1rem;
}
input[type="radio"] {
  padding: 0; // remove padding introduced through code above for mobile safari
}
select {
  option {
    color: $background-color !important;
    background-color: $text-color !important;
    background-color: transparent !important;
  }
}
button.small, .button.small {
  font-size: 0.5rem;
  padding: 0.25rem;
}
input,
select,
textarea {
  &:not([type="button"]) {
    user-select: auto;
  }
}
textarea {
  resize: vertical;
}
::placeholder {
  color: rgba($text-color, 0.5);
}

input[type="search"] {
  -webkit-appearance: textfield;
  appearance: textfield;
}
/* Remove chrome clear button */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  appearance: none;
}
input[type="submit"],
input[type="button"],
button {
  appearance: none;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all $animation-speed ease;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }
}
input[type="password"] {
  letter-spacing: 0.21875em;
  &::placeholder {
    letter-spacing: 0;
  }
}

input:disabled {
  border: 1px solid darken($text-color, 15%);
  background-color: lighten($background-color, 15%);
  color: darken($text-color, 15%);
  cursor: not-allowed;
  pointer-events: all !important;
  &:hover {
    border: 1px solid darken($text-color, 15%);
    background-color: lighten($background-color, 15%);
    color: darken($text-color, 15%);
  }
}

select {
  cursor: pointer;
}

select:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

a,
a:visited,
a:hover,
a:active,
a:focus {
  color: $brand-primary;
  text-decoration: none;
}

.fade-enter,
.fade-appear {
  opacity: 0;
}
.fade-exit {
  opacity: 1;
}
.fade-enter-active,
.fade-appear-active {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity $animation-speed;
}

.link-style {
  color: $brand-primary;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    color: darken($brand-primary, 8%);
    text-decoration: underline;
  }
}

.text-center {
  text-align: center;
}

// input, select { font-size: 100%; }
// @media screen and (max-width: 767px) {
//   select,
//   input[type="text"],
//   input[type="number"],
//   input[type="email"],
//   input[type="tel"],
//   input[type="password"] {
//     font-size: 16px;
//   }
// }

/* Customize the label (the container) */
.checkbox-container {
  display: block;
  position: relative;
  padding-right: 3.5em;
  // margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ span {
      // background-color: #2196f3 !important;
      &:after {
        display: block;
      }
    }
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
    // height: 25px;
    // width: 25px;
    height: 3em;
    width: 3em;
    // background-color: #eee;
    border: 1px solid $white;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }

  // &:hover input ~ span {
  //   background-color: $black;
  // }
}

.checkbox-container span:after {
  left: 1em;
  top: 0.125em;
  width: 1em;
  height: 2em;
  border: solid $brand-primary;
  border-width: 0 0.25rem 0.25rem 0;
  transform: rotate(45deg);
}


svg {
  path.domain {
    opacity: 0;
  }
  .grid {
    .tick {
      line {
        opacity: 0.25 !important;
        // stroke-dasharray: 4 2;
        // stroke-linecap: round;
        // stroke-width: 1;
      }
    }
  }
}





.baseGridLine line {
  stroke: rgba(255, 255, 255, 0.5);
}

.tooltip text {
  font-size: 12px;
  fill: #ffffff;
}

.tooltipLine {
  stroke: #ff007a;
  stroke-width: 1px;
}

.tooltipContent .contentTitle {
  font-weight: bold;
}

.tooltipLinePoint {
  fill: #ff007a;
}

.legendContainer {
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.checkbox {
  margin: 10px;
}


span {
  &.brand-primary {
    color: $brand-primary;
  }

  &.brand-secondary {
    color: $brand-secondary;
  }

  &.brand-danger {
    color: $brand-danger;
  }

  &.brand-success {
    color: $brand-success;
  }

  &.brand-tertiary {
    color: $brand-tertiary;
  }

  &.brand-quaternary {
    color: $brand-quaternary;
  }

  &.brand-quinary {
    color: $brand-quinary;
  }

  &.brand-senary {
    color: $brand-senary;
  }

  &.black {
    color: $black;
  }

  &.white {
    color: $white;
  }
}