@use "sass:math";
@import "../../style-variables";

.header {
  .headerInner {
    h1 {
      font-size: 1.75em;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 2.5rem;
      &.absolute {
        position: absolute;
      }
      svg {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: math.div($grid-padding, 1.5);
      }
    }
    p {
      margin-top: math.div($grid-padding, 2);
      margin-bottom: 0;
      font-size: 0.625em;
      svg {
        margin-right: 1em;
      }
    }
    .hamburger {
      position: absolute;
      z-index: 9999999999;
      top: $grid-padding * 1.25;
      right: $grid-padding;
      border: 0;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      background-color: transparent;
      @media (min-width: calc($breakpoint-extra-large + 76px)) {
        position: fixed;
      }
      span {
        background: $white;
        display: block;
        transform: translatey(0) rotate(0deg);
        transition: all $animation-speed ease;
        height: 2px;
        width: 24px;
        margin-bottom: 6px;
        &.barBot {
          margin-bottom: 0;
        }
      }
      &.menuOpen {
        .barTop {
          transform: translatey(8px) rotate(45deg);
        }
        .barMid {
          opacity: 0;
        }
        .barBot {
          transform: translatey(-8px) rotate(-45deg);
        }
      }
    }
  }
}

.toggled .bar-top {
  will-change: transform;
  transform: translatey(5px) rotate(45deg);
}

.toggled .bar-mid {
  will-change: opacity;
  opacity: 0;
}

.toggled .bar-bot {
  will-change: transform;
  transform: translatey(-5px) rotate(-45deg);
}

.menu {
  position: fixed;
  z-index: 999999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: scroll;
  .buttonGroup {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: ($grid-padding * 3) ($grid-padding * 2);
    max-width: 20rem;
    margin: auto;
    :not(:last-child) {
      margin-bottom: math.div($grid-padding, 2);
    }
    button {
      display: flex;
      align-items: center;
      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;
        path {
          fill: currentColor;
        }
      }
    }
    a.button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }
      
      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;
        path {
          fill: currentColor;
        }
      }
    }
  }
}
