@use "sass:math";
@import "../../style-variables.scss";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  position: absolute;
  padding: $grid-padding;
  top: ($grid-padding * 2);
  left: 0;
  right: 0;
  z-index: 1;
}

.imageContainer {
  position: relative;
  height: auto;
  display: block;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  margin-top: ($grid-padding * 2);
  overflow: hidden;

  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto;
    margin-top: ($grid-padding * 2);
    width: 100%;
  }

  .arrowTop,
  .arrowBottom {
    position: absolute;
    fill: $background-color;
  }

  .arrowTop {
    top: -2px;
    left: -10px;
    width: 110%;
  }

  .arrowBottom {
    bottom: -2px;
    right: -10px;
    width: 110%;
  }

  picture {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 3 * 4);
    position: relative;
    display: block;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  // align-content: flex-end;
  justify-content: flex-end;
  padding: ($grid-padding * 3) ($grid-padding * 2);
  max-width: 20rem;
  margin: auto;
  z-index: 1;

  :not(:last-child) {
    margin-bottom: math.div($grid-padding, 2);
  }

  // border: 1px solid yellow;
  input {
    &[type="file"] {
      display: none;
    }
  }
}

.button {
  font-size: 0.875em;
  line-height: 1.25;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $text-color;
  background-color: $background-color;
  color: $text-color;
  padding: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all $animation-speed ease;
  cursor: pointer;
  text-align: center;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }

  &:hover {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }
}

.skipLinkContainer {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: auto;

  // margin-bottom: 4rem;
  .skip {
    font-size: 0.875em;
    line-height: 1.25;
    font-family: "MessinaSans", sans-serif;
    border-radius: $border-radius;
    outline: none;
    background-color: $background-color;
    color: $text-color;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: all $animation-speed ease;
    cursor: pointer;
    text-align: center;

    &:hover,
    &:active,
    &:focus {
      background-color: $background-color;
      color: $brand-primary;
    }
  }
}