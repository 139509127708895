@use "sass:math";
@import "../../style-variables.scss";

.selectTimeFrame {
  position: relative;
  border: 0;
  padding: 0;
  display: block;
  // width: 100px;
  margin: 0 auto;
  margin-top: 0.5rem;
  appearance: none;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  background-size: 16px 12px;
}

.progressType {
    width: 100%;
    font-size: 2.25rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  
}

.selectProgress {
  margin-top: 1em;
  display: flex;
  overflow: hidden;
  width: 100%;

  :not(:last-child) {
    margin-right: 1rem;
  }

  button,
  .button {
    margin-top: 0.5rem;
    overflow: hidden;
    flex: 1 0 0%;
    border-color: lighten($background-color, 50%);
    color: lighten($background-color, 50%);
    padding-top: 1rem;
    padding-bottom: 1rem;
    &.active {
      border-color: $brand-senary;
      color: $brand-senary;
    }
    // &.decline {
    //   border-color: $brand-quinary;
    //   color: $brand-quinary;
    // }
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $text-color;
      background-color: $text-color;
      color: $background-color;
    }
  }
  // a.button {
  //   display: inline-block;
  //   text-align: center;
  //   font-size: 0.5rem;
  //   padding: 0.25rem;
  //   line-height: 1.25;
  //   font-family: "MessinaSans", sans-serif;
  //   border-radius: $border-radius;
  //   outline: none;
  //   border: 1px solid lighten($background-color, 50%);
  //   color: lighten($background-color, 50%);
  //   text-transform: uppercase;
  //   letter-spacing: 0.2em;
  //   transition: all $animation-speed ease;
  //   cursor: pointer;
  //   padding-top: 1rem;
  //   padding-bottom: 1rem;
  //   &:hover,
  //   &:active,
  //   &:focus {
  //     border: 1px solid $text-color;
  //     background-color: $text-color;
  //     color: $background-color;
  //   }
  // }
}
