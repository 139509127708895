@use "sass:math";
@import "../../style-variables.scss";




.question {
    max-width: 24.875rem;
    margin: 0 auto $grid-padding * 2 auto;
    width: 100%;

    // margin-top: $grid-padding;
    // margin-bottom: $grid-padding;
        

    h5 {
        margin-bottom: $grid-padding * .5;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    .trivia {
        margin: $grid-padding 0 $grid-padding $grid-padding * 2;
    }
}


.answer {
    padding: $grid-padding * .5 $grid-padding;
    border: 1px solid $brand-danger;
    color: $brand-danger;
    margin: $grid-padding 0 $grid-padding $grid-padding * 2;
    position: relative;
}

.selected {
    &::before {
        content: "";
        position: absolute;
        left: - $grid-padding * 2;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-left: $grid-padding * 1.5 solid $white;
        border-top: $grid-padding * .75 solid transparent;
        border-bottom: $grid-padding * .75 solid transparent;
    }
}

.correct {
    color: $brand-secondary;
    border-color: $brand-secondary;
}