@use "sass:math";
@import "../../style-variables.scss";

.preset {
  flex: 1 1 0%;
  overflow: visible;
  text-align: center;
  // background-color: red;
  padding: 0 0.5rem;
  margin-bottom: 2rem;

  h3 {
    color: inherit;
    margin-bottom: 0.25rem;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    svg {
      margin-left: math.div($grid-padding, 2);
    }
  }
  .duration {
    font-size: 0.75em;
    color: lighten($background-color, 50%);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.section {
  position: relative;
  //   margin-top: 2rem;
  //   margin-left: -$grid-padding;
  //   margin-right: -$grid-padding;
  // border: 1px solid red;
  //   @media (min-width: $breakpoint-extra-large) {
  //     margin: 0 auto 0;
  //     width: 100%;
  //   }
}

.details {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .round {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  span {
    font-size: 0.8125em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.overlaySectionTop {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  //   border: 1px solid red;
  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto 0;
    width: 100%;
  }
}

.overlaySectionBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.userSection {
  // border: 1px solid green;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  .userLeft,
  .userRight {
    flex: 1 0 0%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h1,
  h2,
  h3 {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-bottom: 0;
    padding: 0 1rem;
  }
  h1 {
    flex: 1 0 0%;
    font-size: 8vw;
    @media (min-width: $breakpoint-extra-large) {
      font-size: 6rem;
    }
    // font-size: 1.75em;
  }
  h3 {
    line-height: normal;
  }
  // .userLeft {
  //   border: 1px solid red;
  // }
  // .userRight {
  //   border: 1px solid blue;
  // }
}

.imageContainer {
  position: relative;
  height: auto;
  display: block;
  z-index: -1;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  overflow: hidden;
  display: flex;
  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto;
    width: 100%;
  }
  .leftImage,
  .rightImage {
    overflow: hidden;
    width: 50%;
    position: relative;
  }
  .arrowTop,
  .arrowBottom {
    position: absolute;
    fill: $background-color;
  }
  .leftImage {
    .arrowTop {
      top: -2px;
      left: -10px;
      width: 110%;
    }
    .arrowBottom {
      bottom: -2px;
      left: -10px;
      width: 110%;
    }
  }
  .rightImage {
    .arrowTop {
      top: -2px;
      right: -10px;
      width: 110%;
    }
    .arrowBottom {
      bottom: -2px;
      right: -10px;
      width: 110%;
    }
  }
  picture {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 3 * 4);
    position: relative;
    display: block;
    z-index: -1;
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.section {
  margin-bottom: 2rem;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    h3 {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      background-color: $black;
      align-self: center;
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-200%);

        left: 0;
        right: 0;
        border-top: 1px solid $white;
        z-index: -1;
      }
    }
  }

  .round {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    .left {
      flex: 1 1 0%;
      // background-color: red;
    }
    .center {
      flex: 1 1 0%;
      // background-color: orange;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      h4 {
        margin-bottom: 0;
        text-transform: uppercase;
      }

      button,
      .button {
        border-color: lighten($background-color, 50%);
        color: lighten($background-color, 50%);
        padding: 1rem;
        &:hover,
        &:active,
        &:focus {
          border: 1px solid $text-color;
          background-color: $text-color;
          color: $background-color;
        }
      }
    }
    .right {
      flex: 1 1 0%;
      // background-color: green;
      text-align: right;
      .roundMarker {
        justify-content: flex-end;
      }
    }

    .date {
      font-size: 0.5em;
      color: lighten($background-color, 50%);
    }

    .score {
      flex: 0 0 auto;
      font-weight: 700;
      font-size: 1.875rem;
    }

    .roundMarker {
      display: flex;
      // flex: 1 1 0%;
      // align-self: center;
      //   padding: 0 0.5rem;
      overflow: visible;
      padding: 0 1rem;
      // background-color: red;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $background-color;
        border: 1px solid lighten($background-color, 15%);
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
        // transform: translateY(-15%);
        &.played {
          &::after {
            content: "";
            display: block;
            width: 0.75rem;
            height: 0.75rem;
            border: 1px solid $background-color;
            background: lighten($background-color, 15%);
            border-radius: 50%;
          }
        }

        &.won {
          &::after {
            content: "";
            display: block;
            width: 0.75rem;
            height: 0.75rem;
            border: 1px solid $background-color;
            background: $brand-quaternary;
            border-radius: 50%;
          }
        }

        &.lost {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: 50%;
            // left: 50%;
            display: block;
            width: 1.5rem;
            height: 1px;
            background-color: $white;
            transform: rotate(-45deg);
          }
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            // left: 50%;
            display: block;
            width: 1.5rem;
            height: 1px;
            background-color: $white;
            transform: rotate(45deg);
          }
        }
      }
    }

    .resultMessage {
      font-size: 0.75em;
      font-weight: 700;
      // margin-top: 0.25rem;
      margin-bottom: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    .lost {
      text-transform: uppercase;
      color: $brand-quinary;
    }
    .won {
      text-transform: uppercase;
      color: $brand-senary;
    }
    .draw {
      text-transform: uppercase;
      color: $brand-quaternary;
    }
  }
}

.exercise {
  position: relative;
  display: flex;
  flex: 0 0 48px;
  overflow: hidden;
  hyphens: auto;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // padding: 0 1rem;
  // margin-right: 16px;
  // margin-bottom: 16px;
  // margin: 0.5rem;
  //   border: 1px solid red;
  .svgAnimation {
    position: absolute;
    width: 100%;
    height: 32px;
    left: 0;
    top: 6px;
    z-index: -999;
    // border: 1px solid red;
    display: block;
  }
  p {
    margin: 0.25rem 0;
    font-size: 0.625rem;
    text-align: center;
    &:nth-of-type(2) {
      margin: 0;
      font-weight: 700;
    }
  }
}

.detailLinkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  .left {
    flex: 1 1 0%;
  }
  .right {
    flex: 1 1 0%;
    text-align: right;
  }
  .button {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.25;
    font-family: "MessinaSans", sans-serif;
    border-radius: $border-radius;
    outline: none;
    border: 1px solid $text-color;
    background-color: $background-color;
    color: $text-color;
    padding: 1rem;
    border-color: lighten($background-color, 50%);
    color: lighten($background-color, 50%);
    padding: 1rem;
    font-size: 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    // padding: 0.25rem;
    &:hover,
    &:active,
    &:focus {
      border: 1px solid $text-color;
      background-color: $text-color;
      color: $background-color;
    }
  }
}


.section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    h3 {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      background-color: $black;
      align-self: center;
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-200%);

        left: 0;
        right: 0;
        border-top: 1px solid $white;
        z-index: -1;
      }
    }
  }

  .container {
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;
    @media (min-width: $breakpoint-extra-large) {
      margin-left: 0;
      margin-right: 0;
    }
    .pendingChallenge {
      display: block;
      color: $white;
      padding: math.div($grid-padding, 1) $grid-padding;
      @media (min-width: $breakpoint-extra-large) {
        padding: math.div($grid-padding, 1) 0;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid lighten($background-color, 15%);
      }
      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }

      .info {
        display: flex;
        margin-bottom: 0.25rem;
        .preset {
          flex: 1 0 0%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
          .action {
            font-size: 0.75em;
            font-weight: 700;
            margin-bottom: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              text-transform: uppercase;
            }
          }
        }
        .user {
          flex: 0 0 2.5rem;

          display: flex;
          overflow: hidden;
          align-items: center;
          text-overflow: ellipsis;
          .circle {
            position: relative;
            background-color: lighten($black, 70%);
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            flex: 0 0 2.5rem;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .placeholder {
              width: 1.5rem;
              height: 1.5rem;
              fill: lighten($black, 60%);
            }
          }
        }
      }

      h3 {
        color: inherit;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        svg {
          margin-left: math.div($grid-padding, 2);
        }
      }
      .duration {
        font-size: 0.75em;
        color: lighten($background-color, 50%);
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .workoutStat {
        margin-top: 0.5rem;
        overflow: hidden;
        flex: 1 0 0%;
        &:not(:last-of-type) {
          padding-right: 1rem;
        }
        button,
        .button {
          width: 100%;
          border-color: lighten($background-color, 50%);
          color: lighten($background-color, 50%);
          padding-top: 1rem;
          padding-bottom: 1rem;
          &.accept {
            border-color: $brand-senary;
            color: $brand-senary;
          }
          &.decline {
            border-color: $brand-quinary;
            color: $brand-quinary;
          }
          &:hover,
          &:active,
          &:focus {
            border: 1px solid $text-color;
            background-color: $text-color;
            color: $background-color;
          }
        }
        a.button {
          display: inline-block;
          text-align: center;
          font-size: 0.5rem;
          padding: 0.25rem;
          line-height: 1.25;
          font-family: "MessinaSans", sans-serif;
          border-radius: $border-radius;
          outline: none;
          border: 1px solid lighten($background-color, 50%);
          color: lighten($background-color, 50%);
          text-transform: uppercase;
          letter-spacing: 0.2em;
          transition: all $animation-speed ease;
          cursor: pointer;
          padding-top: 1rem;
          padding-bottom: 1rem;
          &:hover,
          &:active,
          &:focus {
            border: 1px solid $text-color;
            background-color: $text-color;
            color: $background-color;
          }
        }
      }
      hr {
        margin: 0;
        border-color: lighten($background-color, 15%);
      }
      .message {
        flex: 1 1 100%;

        display: flex;
        align-items: center;
        overflow: hidden;
        align-items: center;
        text-overflow: ellipsis;
        //   margin-top: 0.5rem;
        // margin-bottom: 0.5rem;
        .circle {
          position: relative;
          background-color: lighten($black, 70%);
          // background-color: lighten($background-color, 15%);
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-left: 1rem;
          flex: 0 0 2.5rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 2px solid red;
          .svgAnimation {
            position: absolute;
            width: 100%;
            height: 32px;
            left: 0;
            top: 2px;
            display: block;
          }
          .placeholder {
            width: 1.5rem;
            height: 1.5rem;
            fill: lighten($black, 60%);
          }
          // img {
          //   display: block;
          //   width: 100%;
          //   max-width: 100%;
          //   height: auto;
          // }
          // .profileImage {
          //   width: 25rem;
          //   height: 2.5rem;
          //   display: block;
          //   img {
          //     width: 100%;
          //     max-width: 100%;
          //     height: auto;
          //     display: block;
          //   }
          // }
        }
        .messageContent {
          flex: 1 1 100%;
          h3 {
            line-height: 1.2;
            // font-size: 0.875rem;
            margin-bottom: 0;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            // @media (min-width: $breakpoint-small) {
            //   font-size: 1.25rem;
            // }
          }
          p {
            margin-top: 0.125rem;
            margin-bottom: 0;
            font-size: 0.75em;
          }
          .dateSeparator {
            margin-top: 0.125rem;
            margin-bottom: 0;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // background-color: lighten($background-color, 15%);
            // text-align: center;
            font-size: 0.5em;
            // padding: 0.25rem;
            color: lighten($background-color, 50%);
          }
        }
      }
    }
  }
}