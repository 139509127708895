@use "sass:math";
@import "../../style-variables.scss";

.exercise {
  position: relative;
  display: flex;
  flex: 0 0 48px;
  overflow: hidden;
  hyphens: auto;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  // padding: 0 1rem;
  // margin-right: 16px;
  // margin-bottom: 16px;
  // margin: 0.5rem;
  //   border: 1px solid red;
  .svgAnimation {
    position: absolute;
    width: 100%;
    height: 32px;
    left: 0;
    top: 6px;
    z-index: -999;
    // border: 1px solid red;
    display: block;
  }
  p {
    margin: 0.25rem 0;
    font-size: 0.625rem;
    text-align: center;
    &:nth-of-type(2) {
      margin: 0;
      font-weight: 700;
    }
  }
}
