@use "sass:math";
@import "../../style-variables";

.loaderContainer {
  position: fixed;
  z-index: -9999999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.positionRelative {
  position: relative;
}
