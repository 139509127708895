@use "sass:math";
@import "../../style-variables";

.challengeSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  .select {
    position: relative;
    border: 0;
    padding: 0;
    display: block;
    margin: 0;
    margin-top: 0.5rem;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-size: 16px 12px;
  }
}

.section {
  margin-bottom: 2rem;
  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    h3 {
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      background-color: $black;
      align-self: center;
      padding: 0 0.5rem;
      margin-bottom: 0.25rem;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-200%);

        left: 0;
        right: 0;
        border-top: 1px solid $white;
        z-index: -1;
      }
    }
  }

  .container {
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;
    @media (min-width: $breakpoint-extra-large) {
      margin-left: 0;
      margin-right: 0;
    }
    .pendingChallenge {
      display: block;
      color: $white;
      padding: math.div($grid-padding, 1) $grid-padding;
      @media (min-width: $breakpoint-extra-large) {
        padding: math.div($grid-padding, 1) 0;
      }
      &:not(:last-of-type) {
        border-bottom: 1px solid lighten($background-color, 15%);
      }
      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }

      .info {
        display: flex;
        margin-bottom: 0.25rem;
        .preset {
          flex: 1 0 0%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
          .action {
            font-size: 0.75em;
            font-weight: 700;
            margin-bottom: 0.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            span {
              text-transform: uppercase;
            }
          }
        }
        .user {
          flex: 0 0 2.5rem;

          display: flex;
          overflow: hidden;
          align-items: center;
          text-overflow: ellipsis;
          .circle {
            position: relative;
            background-color: lighten($black, 70%);
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            flex: 0 0 2.5rem;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            .placeholder {
              width: 1.5rem;
              height: 1.5rem;
              fill: lighten($black, 60%);
            }
          }
        }
      }

      h3 {
        color: inherit;
        margin-bottom: 0.25rem;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        svg {
          margin-left: math.div($grid-padding, 2);
        }
      }
      .duration {
        font-size: 0.75em;
        color: lighten($background-color, 50%);
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .workoutStat {
        margin-top: 0.5rem;
        overflow: hidden;
        flex: 1 0 0%;
        &:not(:last-of-type) {
          padding-right: 1rem;
        }
        button,
        .button {
          width: 100%;
          border-color: lighten($background-color, 50%);
          color: lighten($background-color, 50%);
          padding-top: 1rem;
          padding-bottom: 1rem;
          &.accept {
            border-color: $brand-senary;
            color: $brand-senary;
          }
          &.decline {
            border-color: $brand-quinary;
            color: $brand-quinary;
          }
          &:hover,
          &:active,
          &:focus {
            border: 1px solid $text-color;
            background-color: $text-color;
            color: $background-color;
          }
        }
        a.button {
          display: inline-block;
          text-align: center;
          font-size: 0.5rem;
          padding: 0.25rem;
          line-height: 1.25;
          font-family: "MessinaSans", sans-serif;
          border-radius: $border-radius;
          outline: none;
          border: 1px solid lighten($background-color, 50%);
          color: lighten($background-color, 50%);
          text-transform: uppercase;
          letter-spacing: 0.2em;
          transition: all $animation-speed ease;
          cursor: pointer;
          padding-top: 1rem;
          padding-bottom: 1rem;
          &:hover,
          &:active,
          &:focus {
            border: 1px solid $text-color;
            background-color: $text-color;
            color: $background-color;
          }
        }
      }
      hr {
        margin: 0;
        border-color: lighten($background-color, 15%);
      }
      .message {
        flex: 1 1 100%;

        display: flex;
        align-items: center;
        overflow: hidden;
        align-items: center;
        text-overflow: ellipsis;
        //   margin-top: 0.5rem;
        // margin-bottom: 0.5rem;
        .circle {
          position: relative;
          background-color: lighten($black, 70%);
          // background-color: lighten($background-color, 15%);
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          margin-left: 1rem;
          flex: 0 0 2.5rem;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          // border: 2px solid red;
          .svgAnimation {
            position: absolute;
            width: 100%;
            height: 32px;
            left: 0;
            top: 2px;
            display: block;
          }
          .placeholder {
            width: 1.5rem;
            height: 1.5rem;
            fill: lighten($black, 60%);
          }
          // img {
          //   display: block;
          //   width: 100%;
          //   max-width: 100%;
          //   height: auto;
          // }
          // .profileImage {
          //   width: 25rem;
          //   height: 2.5rem;
          //   display: block;
          //   img {
          //     width: 100%;
          //     max-width: 100%;
          //     height: auto;
          //     display: block;
          //   }
          // }
        }
        .messageContent {
          flex: 1 1 100%;
          h3 {
            line-height: 1.2;
            // font-size: 0.875rem;
            margin-bottom: 0;
            // text-overflow: ellipsis;
            // white-space: nowrap;
            // overflow: hidden;
            // @media (min-width: $breakpoint-small) {
            //   font-size: 1.25rem;
            // }
          }
          p {
            margin-top: 0.125rem;
            margin-bottom: 0;
            font-size: 0.75em;
          }
          .dateSeparator {
            margin-top: 0.125rem;
            margin-bottom: 0;
            // overflow: hidden;
            // text-overflow: ellipsis;
            // background-color: lighten($background-color, 15%);
            // text-align: center;
            font-size: 0.5em;
            // padding: 0.25rem;
            color: lighten($background-color, 50%);
          }
        }
      }
    }
  }
}

a.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  text-align: center;
  outline: none;
  border: 1px solid lighten($background-color, 15%);
  color: lighten($background-color, 50%);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color $animation-speed ease, border $animation-speed ease,
    background-color $animation-speed ease;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $text-color;
  background-color: $background-color;
  color: $text-color;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-extra-large) {
    max-width: $boxed-width - 2 * $grid-padding;
  }
  svg {
    fill: currentColor;
    margin-right: 1rem;
    path {
      fill: currentColor;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }
}
