@use "sass:math";
@import "../../style-variables";

.container {
    margin-top: -1.5em;

    .progressContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .levelCircle {
        border: 2px solid $background-color;
        height: 2em;
        width: 2em;
        padding: 0;
        margin: 0;
        flex-shrink: 0;
        flex-grow: 0;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $brand-primary;
        color: $background-color;
        margin-left: -0.5rem;
        margin-right: -2px;
        z-index: 1;

        h3 {
            font-size: 1rem;
            margin-bottom: 0;
        }
    }

    .progressBarContainer {
        position: relative;
        height: 12px;
        width: 61.8%;
        background-color: red;

        .progressBar {
            background-color: lighten($background-color, 50%);
            border: 2px solid $background-color;
            position: absolute;
            left: 0;
            top: 0;
            height: 12px;
            width: 100%;


        }

        .actualProgress {
            background-color: $brand-primary;
            position: absolute;
            left: 2px;
            top: 2px;
            height: 8px;
            transition: 1s cubic-bezier(1, 0, 0, 1);
            will-change: transform;
            transform-origin: left center;
            animation-name: progress;
            animation-duration: 1s;
            animation-timing-function: cubic-bezier(1, 0, 0, 1);
        }
    }

    p {
        position: relative;
        left: 2.5em;
        top: -0.5em;
        color: inherit;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.625em;
        margin-bottom: 0;
    }
}

@keyframes progress {
    from {
        transform: scale(0, 1);
    }

    to {
        transform: scale(1, 1);
    }
}