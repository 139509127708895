@use "sass:math";
@import "../../style-variables";



.segmentDetails {
  font-size: 0.75rem;
  // background-color: red;
  grid-column: 1 / -1;
  // display: grid;

  display: grid;
  // grid-gap: calc($grid-padding * 0.125);
  // padding-top: $grid-padding * 0.5;
  margin-top: calc($grid-padding * 0.5);


  .challengeStatus {
    // background-color: orange;
    font-size: 0.625rem;


    .info {
      // background-color: yellow;
      display: grid;
      grid-template-columns: 2fr 2fr 3fr 1fr 0.75fr;
      grid-gap: calc($grid-padding * 0.5);
      margin-top: calc($grid-padding * 0.125);

      // &>div {
      //   border: 1px solid green;
      // }

      .singleChallenge {
        grid-column: span 3;
        padding-left: .75rem;

        svg {
          position: absolute;
          margin-left: -.75rem;
          transform: translateY(1.5px);
        }

        &.active {
          svg {
            fill: $brand-success;
          }
        }
      }

      .singleAddedPoints {
        grid-column: span 1;
        text-align: right;

        color: rgba($white, 0.25);
        font-size: 0.75rem;
        white-space: nowrap;

        &.active {
          color: $brand-primary;
        }

      }

      .streakChallenge {
        grid-column: span 2;
        padding-left: 0.75rem;

        svg {
          position: absolute;
          margin-left: -0.75rem;
          transform: translateY(1.5px);
        }

        &.active {
          svg {
            fill: $brand-success;
          }
        }
      }

      .streakAddedPoints {
        grid-column: span 2;
        text-align: right;

        color: rgba($white, 0.25);
        font-size: 0.75rem;

        span.active {
          color: $brand-primary;
        }

      }

    }


  }


  .addedRankingPoints {
    // background-color: red;

    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 0.75fr;
    grid-gap: calc($grid-padding * 0.5);
    // justify-content: center;
    // align-items: center;
    // font-size: 0.75rem;

    margin-top: calc($grid-padding * 0.125);

    &.marginTop {
      margin-top: calc($grid-padding * 0.75);
    }

    // &>div {
    //   border: 1px solid green;
    // }

    .title {
      grid-column: span 2;
      h4 {
        text-transform: none;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        // color: blue;
      }
    }



    .challengeRank {
      // font-size: 0.75rem;
      text-align: right;
      color: rgba($white, 0.25);
      grid-column: span 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      // align-items: center;
      align-items: center;
      position: relative;

      
    }

    .addedPoints {
      color: rgba($white, 0.25);
      // font-size: 0.75rem;
      text-align: right;
      white-space: nowrap;

      &.active {
        color: $brand-primary;

        &:before {
          content: "+ ";
        }
      }

    }


    .addedTotalPoints {
      color: $white;
      text-align: right;
    }
  }

  .challengeEndsIn {

    display: grid;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 0.75fr;
    grid-gap: calc($grid-padding * 0.5);
    margin-top: calc($grid-padding * 1);

    .title {
      grid-column: span 2;
      font-size: 0.625rem;
    }

    .upcomingWeekDateTime {
      text-align: right;
      letter-spacing: 0;
      grid-column: span 3;
      white-space: nowrap;
      font-size: 0.625rem;
    }
  }

}