@use "sass:math";
@import "../../style-variables";

.workoutStat {
  overflow: hidden;
  flex: 1 0 0%;
  h4,
  p {
    color: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.625em;
    margin-bottom: 0;
  }
  h4 {
    text-transform: uppercase;
  }
  &:not(:last-of-type) {
    padding-right: 0.5rem;
  }
}
.horizontal {
  display: flex;
  justify-content: space-between;
  h4,
  p {
    text-transform: none;
    min-width: 6em;
    
  }
  p {
    text-align: right;
  }
  h4 {
    font-weight: 400 !important;
    font-style: normal;
    min-width: 3.5rem;
    margin-bottom: 0.25rem;
  }
  p {
    font-weight: 900 !important;
  }
  &:not(:last-of-type) {
    padding-right: 0;
  }
}
