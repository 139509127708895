@use "sass:math";
@import "../../style-variables.scss";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  position: absolute;
  padding: $grid-padding;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.video {
  display: block;
  position: relative;
  // margin-top: -8rem;
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  z-index: -1;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: scroll;
  margin-left: - $grid-padding;
  margin-right: - $grid-padding;

  .buttonGroup {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: ($grid-padding * 3) ($grid-padding * 2);
    max-width: 20rem;
    margin: auto;

    :not(:last-child) {
      margin-bottom: math.div($grid-padding, 2);
    }

    button {
      display: flex;
      align-items: center;

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }

    a.button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }
  }
}

.small {
  font-size: 0.625em;
  color: lighten($background-color, 50%);
}