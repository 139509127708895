@use "sass:math";
@import "../../style-variables";

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $grid-padding;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: auto;
  max-width: $boxed-width;
  text-align: center;
}
