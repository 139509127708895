@use "sass:math";
@import "../../style-variables.scss";

.divider {
    margin-top: $grid-padding * 1.5;
    font-size: 0.75em;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    color: lighten($background-color, 50%);
    background-color: lighten($background-color, 15%);
    font-weight: 700;
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;
    padding-left: $grid-padding;
    padding-right: $grid-padding;

    @media (min-width: $breakpoint-extra-large) {
        margin-left: 0;
        margin-right: 0;
    }

    p {
        margin-bottom: 0;
    }
}

.results {
    margin-bottom: $grid-padding * 1;

    .result {
        padding-top: $grid-padding * 1.5;
        padding-bottom: $grid-padding * 1.5;
        margin-left: -$grid-padding;
        margin-right: -$grid-padding;
        padding-left: $grid-padding;
        padding-right: $grid-padding;

        cursor: pointer;
        // transition: background-color 0.3s ease;


        &:not(:last-of-type) {
            border-bottom: 1px solid lighten($background-color, 15%);
        }

        @media (min-width: $breakpoint-extra-large) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .visibleRow {
            display: flex;
            flex-direction: row;
            align-items: center; // Center align items vertically

            p {
                font-size: 0.625em;
                margin-bottom: $grid-padding * .25;
            }

            h3 {
                margin-bottom: 0;
            }

            .svgAnimation {
                width: 40px;
                height: 40px;
            }

            .checkmark {
                border: 1px solid $white;
                width: 36px;
                height: 36px;
                margin-left: auto;
                position: relative;

                &.checked {
                    &:before {
                        content: "";
                        position: absolute;
                        left: 2px;
                        right: 2px;
                        top: 2px;
                        bottom: 2px;
                        background-color: $brand-secondary;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 16px;
                        height: 10px;
                        border-left: 4px solid $background-color;
                        border-bottom: 4px solid $background-color;
                        transform: translate(-50%, -65%) rotate(-45deg);
                    }
                }
            }
        }

        .details {
            height: 0;
            overflow: hidden;
            transition: height 0.25s ease-in-out, opacity 0.25s ease-in-out;
            opacity: 0; // Start hidden

            .marginTop {
                margin-top: $grid-padding * 1.5;
            }



            &.visible {
                height: auto; // Set to auto initially for calculation
                opacity: 1; // Fade in as content expands
            }

            .workoutStat {
                overflow: hidden;
                flex: 1 0 0%;

                h4,
                p {
                    color: inherit;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 0.625em;
                    margin-bottom: 0;
                }

                h4 {
                    text-transform: uppercase;
                }

                &:not(:last-of-type) {
                    padding-right: 0.5rem;
                }
            }

            .workoutHinweise {
                width: 100%;

                h4,
                p {
                    font-size: 0.625em;
                    margin-bottom: 0;
                }

                h4 {
                    text-transform: uppercase;
                }

            }
        }
    }
}



.error {
    color: $brand-danger;
}