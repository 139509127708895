@use "sass:math";
@import "../../style-variables.scss";

a.button {
  margin: 2rem auto;
  font-size: 0.875em;
  line-height: 1.25;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $text-color;
  background-color: $background-color;
  color: $text-color;
  padding: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all $animation-speed ease;
  cursor: pointer;
  width: 18rem;
  max-width: 18rem;
  text-align: center;
  &:hover,
  &:active,
  &:focus {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }
}

.markdown {
  h2, h3, h4, h5, h6 {
    margin-top: 2em;
  }
}
