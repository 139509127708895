@use "sass:math";
@import "../../style-variables";


.container {
  width: 100%;
  max-width: 20rem;
  // padding: 1rem;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;

    input {
      &[type="submit"] {
        padding: 1rem 2rem;
        margin-top: 1rem;
        // align-self: flex-start;
      }
    }

    select {
      position: relative;
      display: block;
      -webkit-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: right 1rem center;
      background-size: 16px 12px;
    }

    label {
      display: block;

      &>input,
      &>select {
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-top: 0.25rem;
      }

      &>.formRow {
        margin-top: 0.25rem;
      }
    }


    .button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      text-align: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      &:hover {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }
    }

    .warning {
      margin: 0;
    }

    .error {
      color: $brand-danger;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-danger;
    }

    .success {
      color: $brand-success;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-success;
    }

    .createButton {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      border-color: $brand-success;
      color: $brand-success;

      &:hover,
      &:active,
      &:focus {
        color: $black;
        background-color: $text-color;
        border-color: $text-color;

      }
    }

  }

  .success {
    color: $brand-success;
  }

}

pre.licenseKeys {
  padding: 1rem;
  user-select: text;
  background-color: lighten($black, 20%);
  overflow: scroll;

  span {
    display: block;
    user-select: text;
  }
}