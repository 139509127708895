@use "sass:math";
@import "../../style-variables.scss";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  position: absolute;
  padding: $grid-padding;
  top: 0;
  left: 0;
  right: 0;

  .userRole {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    // justify-items: center;
    align-items: center;
    // background-color: red;
    object {
      width: 1.5rem;
      height: 1.5rem;
      // background-color: red;
    }
    // svg {
    //   width: 1rem;
    //   height: 1rem;
    // }
    p {
        color: $white;
        margin-bottom: 0;
        // font-style: normal;
      }
    }
}

.imageContainer {
  position: relative;
  height: auto;
  display: block;
  z-index: -1;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  margin-bottom: 2rem;
  overflow: hidden;

  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto;
    width: 100%;
    margin-bottom: 2rem;
  }

  .arrowTop,
  .arrowBottom,
  .arrowBackground {
    position: absolute;
    fill: $background-color;
  }

  .arrowTop {
    top: -2px;
    left: -10px;
    width: 110%;
  }

  .workoutOverlay {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 5rem 1rem 2rem;

    @media (min-width: $breakpoint-small) {
      padding: 8rem 2rem;
    }

    @media (min-width: $breakpoint-medium) {
      padding: 8rem 2rem;
    }

    @media (min-width: $breakpoint-large) {
      padding: 12rem 8rem;
    }

    h2 {
      margin-bottom: 0;
      text-align: right;
      padding-right: 1rem;

      @media (min-width: $breakpoint-small) {
        padding-right: 3rem;
      }
    }

    h3 {
      text-align: right;
    }

    .arrowBackground {
      opacity: 0.7;
      top: 0;
      left: -10px;
      width: 110%;
      z-index: -1;
    }
  }

  .arrowBottom {
    bottom: -2px;
    right: -10px;
    width: 110%;
  }

  picture {
    width: 100%;
    height: 0;
    padding-top: calc(100% / 3 * 4);
    position: relative;
    display: block;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: scroll;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  .buttonGroup {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: ($grid-padding * 3) ($grid-padding * 2);
    max-width: 20rem;
    margin: auto;

    :not(:last-child) {
      margin-bottom: math.div($grid-padding, 2);
    }

    button {
      display: flex;
      align-items: center;

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }

    a.button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }
  }
}

.small {
  font-size: 0.625em;
  color: lighten($background-color, 50%);
}




a.fixedButton,
.fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  text-align: center;
  outline: none;
  color: lighten($background-color, 50%);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color $animation-speed ease, border $animation-speed ease,
  background-color $animation-speed ease;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $brand-primary;
  background-color: $brand-primary;
  color: $background-color;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: $breakpoint-extra-large) {
    max-width: $boxed-width - 2 * $grid-padding;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: currentColor;
      width: 1.25rem;
      height: auto;

      path {
        fill: currentColor;
      }
    }
  }


  &:hover,
  &:active,
  &:focus {
    border: 1px solid $brand-primary;
    background-color: $brand-primary;
    color: $background-color;
  }
}