@use "sass:math";
@import "../../style-variables";


.container {
  width: 100%;
  max-width: 20rem;
  // padding: 1rem;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;

    .error {
      color: $brand-danger;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-danger;
    }

    .success {
      color: $brand-success;
      margin: 0;
      padding: $grid-padding $grid-padding;
      border: 1px solid $brand-success;
    }

    .createButton {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      border-color: $brand-success;
      color: $brand-success;
  
      &:hover,
      &:active,
      &:focus {
        color: $black;
        background-color: $text-color;
        border-color: $text-color;
  
      }
    }

  }
}