@use "sass:math";
@import "../../style-variables";

.scrollContainer {
  width: 100%;
  overflow-x: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

// object {
//   position: absolute;
//   width: 100%;
//   height: 44px;
//   left: 0;
//   top: 0;
//   z-index: -999;
//   // border: 1px solid red;
//   display: block;
// }