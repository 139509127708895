@use "sass:math";
@import "../../style-variables";

.challengeSelector {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
  .select {
    position: relative;
    border: 0;
    padding: 0;
    display: block;
    margin: 0;
    margin-top: 0.5rem;
    appearance: none;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='square' stroke-linejoin='square' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    background-size: 16px 12px;
  }
}

.section {
  // margin-bottom: 2rem;

  .container {
    margin-left: -$grid-padding;
    margin-right: -$grid-padding;
    @media (min-width: $breakpoint-extra-large) {
      margin-left: 0;
      margin-right: 0;
    }
    
  }
}
