@use "sass:math";
@import "../../style-variables.scss";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  position: absolute;
  padding: $grid-padding;
  top: 0;
  left: 0;
  right: 0;
}

.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.small {
  font-size: 0.625em;
  color: lighten($background-color, 50%);
}


.noWorkoutContainer {
  align-self: stretch;
  justify-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $grid-padding;
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: $boxed-width;
  text-align: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  .circle {
    cursor: pointer;
    border: 1px solid $white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $brand-danger;
    margin-bottom: 1rem;

    .placeholder {
      width: 1.5rem;
      height: 1.5rem;
      fill: $white;
    }
  }

  h2 {
    text-align: center;
  }

  p,
  a {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    font-size: 0.875rem;
    font-family: "MessinaSans", sans-serif;

    .email {
      text-transform: none;
      color: $brand-danger;
      letter-spacing: .05em;
    }
  }

  .buttonGroup {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: ($grid-padding * 2) ($grid-padding * 2);

    .deleteButton {
      background-color: $brand-danger;
      color: $text-color;

      &:hover,
      &:active,
      &:focus {
        background-color: darken($brand-danger, 15%);
        color: $text-color;
      }
    }
  }
}




.marginBottom > div:last-child > div:last-child {
    margin-bottom: 3 * $grid-padding;
    }
    
    .marginBottom {
      :not(:last-child) {
        &> :last-child>div {
          border-bottom: 1px solid lighten($background-color, 15%);
        }
      }
    }

.outerContainer {
  display: flex;
  width: 100%;
  cursor: default;

   

  .innerContainer {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    cursor: default;

    button,
    .button {
      padding: 0.5rem 1rem;

      &.delete {
        border: 1px solid $text-color;
        background-color: $brand-danger;
        color: $text-color;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid $text-color;
          background-color: darken($brand-danger, 15%);
          color: $text-color;
        }
      }

      &.verify {
        border: 1px solid $brand-primary;

        color: $brand-primary;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid $text-color;

          color: $black;
        }
      }


    }

    .licensekey {
      display: block;
      cursor: default;
      color: $white;
      padding: math.div($grid-padding, 1.5) $grid-padding;

      @media (min-width: $breakpoint-extra-large) {
        padding: math.div($grid-padding, 1.5) 0;
      }

      &.crud {
        cursor: default;
      }

      &:not(:last-of-type) {
        border-bottom: 1px solid lighten($background-color, 15%);
      }

      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }

      h3 {
        color: inherit;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;

        svg {
          margin-left: math.div($grid-padding, 2);
        }
      }

      .licensekeyStat {
        flex: 1 0 0%;
        overflow: hidden;

        h4,
        p {
          color: inherit;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 0.625em;
          margin-bottom: 0;
        }

        h4 {
          text-transform: uppercase;
        }

        &:not(:last-of-type) {
          padding-right: 0.5rem;
        }
      }
    }
  }

}