@use "sass:math";
@import "../../style-variables.scss";

.idInput {
    margin-top: $grid-padding * 2;
    margin-bottom: $grid-padding * 2;

    label {
        display: block;

        input {
            margin-top: $grid-padding * .5;
            display: block;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
        }
    }
}

.details {
    margin-top: $grid-padding * 2;
    margin-bottom: $grid-padding * 2;

    pre {
        user-select: auto;
    }
}