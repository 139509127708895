@use "sass:math";
@import "../../style-variables.scss";

.form {
  padding: $grid-padding;
  width: 100%;
  max-width: 20rem;
  margin: auto;

  .formRow {
    display: flex;
    justify-content: space-between;
    input {
      flex: 0 0 31%;
    }
  }
  input {
    display: block;
    width: 100%;
    margin-bottom: math.div($grid-padding, 2);
    // &:last-of-type {
    //   margin-bottom: ($grid-padding * 2);
    // }
    &[type="submit"] {
      margin-top: ($grid-padding * 1.5);
      margin-bottom: ($grid-padding * 2);
    }
  }
  p {
    margin-bottom: math.div($grid-padding, 2);
  }
  .formText {
    color: rgba($text-color, 0.5);
    margin-bottom: 1rem;
  }
  label {
    font-size: 0.875rem;
    input {
      margin-top: math.div($grid-padding, 4);
    }
  }
}

.footer {
  margin: 0 auto;
  .skip {
    font-size: 0.875em;
    line-height: 1.25;
    font-family: "MessinaSans", sans-serif;
    border-radius: $border-radius;
    outline: none;
    // border: 1px solid $text-color;
    background-color: $background-color;
    color: $text-color;
    padding: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: all $animation-speed ease;
    cursor: pointer;
    text-align: center;
    &:hover,
    &:active,
    &:focus {
      // border: 1px solid $text-color;
      background-color: $background-color;
      color: $brand-primary;
    }
  }
}
