@use "sass:math";
@import "../../style-variables.scss";

.fullscreenOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;
  overflow-y: scroll;
  padding: $grid-padding * .5;

  .popup {
    backdrop-filter: blur(8px);
    padding: $grid-padding * 2 $grid-padding * 1;
    width: 100%;
    max-width: 40rem;
    margin: auto;
    text-align: center;
    box-shadow: rgba($white, 0.35) 0px 300px 300px -100px inset;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: $breakpoint-small) {
      padding: $grid-padding * 4 $grid-padding * 2;
    }

    .popupContent {
      width: 100%;
      overflow-x: hidden;

      .popupText {
        width: 100%;
        margin-Bottom: $grid-padding * 3;

        .title {
          @media (max-width: $breakpoint-small) {
            font-size: 2rem;
          }
        }
      }

      .popupButton {
        padding: $grid-padding $grid-padding * 2;
        width: 100%;
        max-width: 16rem;


        &:focus {
          border-color: $white;
          background-color: $black;
          color: $white;
        }

        &:hover,
        &:active {
          border-color: $black;
          background-color: $white;
          color: $black;
        }
      }
    }
  }
}