@use "sass:math";
@import "../../style-variables";

a.fixedButton, .fixedButton {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  display: inline-block;
  text-align: center;
  outline: none;
  border: 1px solid lighten($background-color, 15%);
  color: lighten($background-color, 50%);
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: color $animation-speed ease, border $animation-speed ease,
    background-color $animation-speed ease;
  cursor: pointer;
  font-size: 0.875rem;
  font-family: "MessinaSans", sans-serif;
  border-radius: $border-radius;
  outline: none;
  border: 1px solid $text-color;
  background-color: $background-color;
  color: $text-color;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: $breakpoint-extra-large) {
    max-width: $boxed-width - 2 * $grid-padding;
  }
  svg {
    fill: currentColor;
    margin-right: 1rem;
    path {
      fill: currentColor;
    }
  }

  &:hover,
  &:active,
  &:focus {
    border: 1px solid $text-color;
    background-color: $text-color;
    color: $background-color;
  }
}
