.axisX {
  text {
    text-anchor: middle;
  }
}

.axisY {
  text {
    text-anchor: end;
  }
}
