@use "sass:math";
@import "../../style-variables";

.dateSeparator {
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: lighten($background-color, 15%);
  text-align: center;
  font-size: 0.5em;
  padding: 0.25rem;
  color: lighten($background-color, 50%);
}
