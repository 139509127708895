@use "sass:math";
@import "../../style-variables";

.subtitle {
  letter-spacing: 0;
  text-align: center;
  margin: 1rem 1rem 2rem 1rem;
}
.container {
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;
  @media (min-width: $breakpoint-extra-large) {
    margin-left: 0;
    margin-right: 0;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.85);
  backdrop-filter: blur(5px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;


    .circle {
      cursor: pointer;
      // background-color: lighten($black, 70%);
      border: 1px solid $white;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $brand-tertiary;
      margin-bottom: 1rem;
      .placeholder {
        width: 1.5rem;
        height: 1.5rem;
        fill: lighten($black, 60%);
      }
    }



  p, a {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.2em;
    font-size: 0.875rem;
    font-family: "MessinaSans", sans-serif;
  }
}
