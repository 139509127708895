@use "sass:math";
@import "../../style-variables";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $grid-padding;
  width: 100%;
  // height: 100%;
  margin: 0 auto;
  // overflow-x: hidden;
  max-width: $boxed-width;
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.fullHeight {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  &.fixedButtonAtBottom {
    padding-bottom: 3 * $grid-padding;
  }
}
