@use "sass:math";
@import "../../style-variables.scss";

@font-face {
  font-family: "Roboto Mono";
  font-style: normal;
  font-weight: 400;
  src: url('../../Fonts/Roboto_Mono/static/RobotoMono-Regular.ttf') format('truetype')
}

.container {
  width: 100%;
  max-width: 20rem;
  // padding: 1rem;
  margin: 0 auto;

  .form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 4rem;

    input {
      &[type="submit"] {
        padding: 1rem 2rem;
        margin-top: 1rem;
        // align-self: flex-start;
      }
    }

    label {
      display: block;

      &>input,
      &>select {
        display: flex;
        width: 100%;
        max-width: 100%;
        margin-top: 0.25rem;
      }

      &>.formRow {
        margin-top: 0.25rem;
      }
    }


    .licenseKeyInput {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      letter-spacing: 3px;
      // padding: 1rem 2rem;
      // background-color: blue;
      font-family: "Roboto Mono", monospace;
    }

    p {
      margin-bottom: math.div($grid-padding, 2);
    }
  }
}

.success {
  color: $brand-success;
  margin: 0;
  padding: $grid-padding $grid-padding;
  border: 1px solid $brand-success;
}

.error {
  color: $brand-danger;
  margin: 0;
  padding: $grid-padding $grid-padding;
  border: 1px solid $brand-danger;
}