@import "../../style-variables";

// .quiz {
//     width: 600px;
//     margin: 0 auto;
//     text-align: center;
// }

// .timer {
//     font-size: 20px;
//     margin-bottom: 20px;
// }

// .navigation {
//     margin-top: 20px;
// }

// .navigation button {
//     margin: 0 10px;
//     padding: 10px 20px;
//     font-size: 16px;
// }

// .question {
//     margin-bottom: 20px;
// }

// .answers {
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
// }

// .answer {
//     margin: 10px 0;
// }

// .question {
//     margin-bottom: 1rem;
//     h3 {
//         margin-bottom: 2rem;
//     }
// }

.answers {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    label {
        // position: relative;
        padding: 0;
        margin: 0;
        // width: 100%;
        // max-width: 100%;
        // margin-bottom: 1rem;
        // padding-left: 1.5rem;
        // border: 1px solid white;


        padding: $grid-padding * .5 $grid-padding;
        border: 1px solid $white;
        color: $white;
        margin: $grid-padding * .5 0;
        position: relative;
        cursor: pointer;

        &:has(input[type="radio"]:checked) {
            border-color: $brand-primary;
            background-color: $brand-primary;
            color: $background-color;
        }

    }

    input {
        position: absolute;
        left: -9999999999999999px;
        top: -9999999999999999px;
        accent-color: $brand-primary;
    }
}

