@use "sass:math";
@import "../../style-variables";

.pageHeader {
  text-overflow: ellipsis;
  hyphens: auto;
  overflow-x: hidden;
  // position: absolute;
  padding: $grid-padding;
  // top: 0;
  // left: 0;
  // right: 0;

  .userRole {
    display: flex;
    flex-direction: row;
    // justify-content: flex-start;
    // justify-items: center;
    align-items: center;

    // background-color: red;
    object {
      width: 1.5rem;
      height: 1.5rem;
      // background-color: red;
    }

    // svg {
    //   width: 1rem;
    //   height: 1rem;
    // }
    p {
      color: $white;
      margin-bottom: 0;
      // font-style: normal;
    }
  }
}

.noSeason {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  p {
    color: $brand-danger;
    margin-bottom: 0;
  }
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: scroll;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  .buttonGroup {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    padding: ($grid-padding * 3) ($grid-padding * 2);
    max-width: 20rem;
    margin: auto;

    :not(:last-child) {
      margin-bottom: math.div($grid-padding, 2);
    }

    button {
      display: flex;
      align-items: center;

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }

    a.button {
      font-size: 0.875em;
      line-height: 1.25;
      font-family: "MessinaSans", sans-serif;
      border-radius: $border-radius;
      outline: none;
      border: 1px solid $text-color;
      background-color: $background-color;
      color: $text-color;
      padding: 1rem;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      transition: all $animation-speed ease;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid $text-color;
        background-color: $text-color;
        color: $background-color;
      }

      svg {
        margin-left: 1.5rem;
        fill: currentColor;
        margin-right: 1rem;

        path {
          fill: currentColor;
        }
      }
    }
  }
}

.seasonContainer {
  position: relative;
  height: auto;
  display: block;
  z-index: -1;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  margin-top: 1rem;
  margin-bottom: 2rem;
  // overflow: hidden;

  @media (min-width: $breakpoint-extra-large) {
    margin: 0 auto;
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    display: block;
  }

  .seasonOverlay {
    // position: absolute;
    // left: 0;
    // width: 100%;
    // bottom: 0;
    padding: $grid-padding * 2;
    height: auto;
    // background-color: red;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    z-index: 1;
    margin-top: 20%;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      background-image: linear-gradient(transparent, $black);
      height: 100%;


    }


    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      // text-indent: 1em;
      //     padding: 0 0.25em;
      //     display: inline;
      // background-color: #fcf113;
      // box-shadow: 10px 0 0 #fcf113, -10px 0 0 #fcf113;
      // box-decoration-break: clone;
      margin-bottom: 0;
      z-index: 1;
      text-transform: uppercase;
      font-style: normal;
    }

    p {
      font-size: 0.75rem;
      // font-size: 0.875rem;
      margin-top: calc($grid-padding * 0.25);
    }

    span {
      padding: 0.125rem 0.5rem;
      box-decoration-break: clone;
      line-height: 1.3;
      // font-variant: normal;
      // font-style:normal;
      letter-spacing: 0.1rem;
      // font-weight: 700;

      &.teal {
        background-color: rgba(teal, 0.75);
      }

      &.purple {
        background-color: rgba(purple, 0.75);
      }
    }

    .challengeContainer {
      // background-color: red;
      z-index: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      margin-top: 1rem;

      .challenge {
        background-color: rgba($black, 0.75);
        padding: calc($grid-padding * 0.75);
        padding-bottom: 1.5rem;
        border-radius: 1.5rem;
        border: 1px solid rgba($white, 0.25);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // &:has(.challengeCompleted) {
        //   border-color: rgba($brand-success, 0.25);
        // }

        .challengeCompleted {
          position: relative;
          padding: 0 1rem;
          background-color: rgb(169, 215, 54);
          margin-left: -1rem;
          margin-right: -1rem;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          height: 28px;

          p {
            margin-bottom: 0;
            font-size: 0.75rem;
            // position: relative;
            // margin-left: -.125rem;

            // .emoji {
            //   color: darken($brand-success, 20%);
            //   left: $grid-padding * .5;
            // }

            svg {
              width: 3.75rem;
              height: auto;
              position: absolute;
              left: 0.5rem;
              top: -1.125rem;
              stroke: rgb(18, 113, 54);
              path {
                stroke-width: 66;
              }
              // path {
              //   fill: red;
              // }
            }

            .claimed {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              margin-left: 2rem;
              color: $black;
              font-weight: 700;
            }
          }
        }

        .challengeOpen {
          position: relative;
          padding: 0 1rem;
          background-color: rgba($white, 0.25);
          margin-left: -1rem;
          margin-right: -1rem;
          text-transform: uppercase;
          letter-spacing: 0.1rem;
          height: 28px;

          p {
            margin-bottom: 0;
          }
        }

        .emoji {
          position: absolute;
          font-size: 3.25rem;
          left: -.25rem;
          top: 50%;
          transform: translateY(-50%);

          @media screen and (min-width: $breakpoint-small) {
            left: 0;
          }
        }

        .expAmount {
          position: absolute;

          top: 50%;
          transform: translateY(-50%);
          margin-left: 2rem;
          font-size: 1.5rem;
          letter-spacing: -0.05rem;
          font-weight: 700;
          font-family: "MessinaSans", sans-serif !important;
          color: $brand-primary;

          @media screen and (min-width: $breakpoint-small) {
            margin-left: 3rem;
          }
        }

        .expName {
          position: absolute;
          left: 6.5rem;
          // margin-left: -0.75rem;
          font-size: 0.5rem;
          letter-spacing: -0.0rem;
          font-weight: 700;
          font-family: "MessinaSans", sans-serif !important;
          color: $brand-primary;
          text-transform: uppercase;

          // top: 0.25rem;
          @media screen and (min-width: $breakpoint-small) {
            margin-left: 1rem;
          }
        }
      }
    }

    .timer {
      color: $white;
      z-index: 1;
      // text-align: right;
      margin-top: 0.5rem;

      .timerText {
        text-transform: uppercase;
        letter-spacing: 0.2rem;
        font-size: 0.625rem;
      }

      .timerTime {
        white-space: nowrap;
        letter-spacing: 0;
        font-size: 0.875rem;
      }
    }
  }
}



.progress {
  // background-color: red;
  display: flex;
  flex-direction: column;
  row-gap: calc($grid-padding / 2);
  font-size: 0.875rem;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin-bottom: 0;
    font-size: 0.875rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-transform: uppercase;
    margin-bottom: 0;
    font-style: normal;
        font-weight: 700;
        font-size: 0.75rem
  }

  .seasonProgress {
    // background-color: red;
    display: grid;
    grid-template-columns: 2fr 2fr 3fr 1fr 0.75fr;

    grid-gap: calc($grid-padding * 0.5);

    border: 1px solid $white;
    padding: calc($grid-padding * 0.5);

    align-items: center;

    // &>div {
    //   border: 1px solid green;
    // }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin-bottom: 0;
      font-size: 0.5rem;
    }


    .points {
      // background-color: red;
      grid-column: span 1;


      .seasonPoints {
        color: $brand-primary;
        font-weight: 700;
        font-size: 1.25rem;
      }
    }

    .rank {
      // background-color: purple;
      grid-column: span 1;

      width: auto;
      // min-width: 100px;
      margin-left: auto;
      margin-right: auto;

      .seasonRank {
        color: $brand-primary;
        font-weight: 700;
        font-size: 1.25rem;
        
      }
    }

    .seasonEnds {
      // background-color: red;
      grid-column: span 3;
      font-size: 0.625rem;


      // letter-spacing: 0;
      width: auto;
      // min-width: 100px;
      margin-left: auto;
      // text-align: right;
      // font-size: 0.75rem;

      .seasonEndsTitle {
        // font-size: 0.75rem;
        // text-transform: uppercase;
        color: $white;
        
      }
    }




  }


}

