@use "sass:math";
@import "../../style-variables.scss";

.form {
  padding: $grid-padding;
  width: 100%;
  max-width: 20rem;
  margin: auto;
  input {
    display: block;
    width: 100%;
    margin-bottom: math.div($grid-padding, 2);
    &:last-of-type {
      margin-bottom: ($grid-padding * 2);
    }
  }
  p {
    margin-bottom: math.div($grid-padding, 2);
  }
}

.footer {
  margin: 0 auto;
}
